<template>
    <div class="col-md-12" style="margin:0 auto">
        <h1>Utilisateurs</h1>
        <hr>
        <UserTableComponent></UserTableComponent>
    </div>
</template>

<script>
import UserTableComponent from "./UserTableComponent.vue"
export default {
    name:"UserManagementComponent",
    components:{UserTableComponent}
}
</script>