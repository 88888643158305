<template>
   <UserManagementComponent></UserManagementComponent>
</template>

<script>
import UserManagementComponent from "@/components/UserManagementComponent.vue"
export default {
    name:"Utilisateurs",
    components:{UserManagementComponent},
}
</script>