<template>
  <v-dialog v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small v-bind="attrs" v-on="on" v-if="!edit"
        >Ajouter tache</v-btn
      >
      <v-btn small v-bind="attrs" v-on="on" icon v-if="edit"
        ><v-icon>mdi-pencil-outline</v-icon></v-btn
      >
    </template>
    <v-card>
      <v-card-title>{{ titre }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="tacheForm" @submit.prevent="saveForm">
            <div>
              <div v-if="$v.nomTache.$dirty">
                <div class="error" v-if="!$v.nomTache.required">
                  Ce champ est requis
                </div>
              </div>
              <v-text-field
                v-model.trim="$v.nomTache.$model"
                label="Nom tache"
              ></v-text-field>
            </div>
            <v-row>
              <v-col><v-btn type="submit">Enregistrer</v-btn></v-col>
              <v-col> <v-btn @click="close">Fermer</v-btn></v-col></v-row
            >
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "TacheFormComponent",
  props: ["tache_id","projet_id", "btn_text", "titre", "edit"],
  data: () => ({
    dialog: false,
    tache:null,
    nomTache: "",
  }),
  validations: {
    nomTache: {
      required,
    }
  },
  watch: {
    dialog(value) {
      if (value) {
        this.open();
      } else {
        this.close();
      }
    },
  },
  methods: {
    ...mapActions(["addTache", "updateTache"]),
    ...mapGetters(["getTacheById"]),
    editLoadTache() {
      var tache = this.tache;
      this.nomTache = tache.nom_tache;
      this.rEtD = tache.rEtD;
    },
    saveForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (!this.$props.edit) {
          this.addNew();
        } else {
          this.update();
        }
        this.close();
      }
    },
    addNew() {
      var tache = {
        nom_tache: this.nomTache,
        projet:this.projet_id
      };
      this.addTache(tache);
      this.close();
    },
    update() {
    var tache = {
        id:this.tache.id,
        nom_tache: this.nomTache,
        projet:this.projet_id
      };
      this.updateTache(tache);
    },
    close() {
      this.$refs.tacheForm.reset();
      this.$v.$reset();
      this.dialog = false;
    },
    open() {
      if (this.$props.edit == true) {
        this.tache = this.getTacheById()(this.tache_id,this.projet_id);
        this.editLoadTache();
      }
    },
  },
};
</script>