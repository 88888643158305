<template>
  <div id="page">
    <div id="sideNav">
      <SideNavComponent></SideNavComponent>
    </div>
    <div id="mainView" class="col-md-10">
      <h1>Tableau de bord</h1>
      <hr />
      <h2>Entrées de la feuille de temps</h2>
      <EntreesTableComponent
        v-if="dataFetched"
        v-bind:entrees="getSelectedProjetEntreesOrEntrees()"
        :projets="getProjetsDictonary()"
        :headers="headers"
      ></EntreesTableComponent>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EntreesTableComponent from "./EntreesTableComponent";
import SideNavComponent from "./SideNavComponent";
export default {
  name: "DashboardComponent",
  components: {
    EntreesTableComponent,
    SideNavComponent,
  },
  data: () => ({
    user: null,
    graphicsSwitch: false,
    tableSwitch: true,
    dataFetched: false,
  }),
  computed: {
    headers() {
      return [
        {
          text: "Projet",
          value: "nom_projet",
          width: "250px",
          groupable: true,
        },
        { text: "Tache", value: "nom_tache", width: "200px" },
        { text: "Date", value: "date" },
        { text: "Date de la saisie", value: "created" },
        { text: "Heures", value: "nombre_heures" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
  methods: {
    ...mapActions(["fetchProjets", "fetchEntrees"]),
    ...mapGetters([
      "getProjets",
      "getProjet",
      "getEntrees",
      "getSelectedProjetEntrees",
      "getSelectedProjetEntreesOrEntrees",
      "getProjetsDictonary",
      "getProjetsList",
      "getUser",
    ]),
  },
  created() {
    this.user = this.getUser();
    this.fetchProjets().then(() => {
      this.fetchEntrees(this.user.id).then(() => {
        this.dataFetched = true;
      });
    });
  },
};
</script>

<style scoped>
.v-card__text {
  font-weight: bold;
  font-size: 1.5rem;
}

#page {
  display: flex;
  flex-direction: row;
  height: 100%;
}

#page #sideNav {
  background-color: ghostwhite;
  height: 100%;
  box-shadow: 0 0 5px 0 black;
}
</style>