<template>
  <v-container>
    <div>
      <h1>Tableau de bord administrateur</h1>
      <div>
        <hr />
        <br />
        <v-card elevation="2" shaped>
          <v-card-title>Feuilles de temps</v-card-title>
          <v-card-text
            >Gestion des feuilles de temps des utilisateurs</v-card-text
          >
          <v-card-actions
            ><v-btn
              color="blue"
              text
              @click="$router.push({ name: 'adminTimesheet' })"
              >Accéder</v-btn
            ></v-card-actions
          >
        </v-card>
        <br />
        <br />
        <v-card elevation="2" shaped>
          <v-card-title>Entrées</v-card-title>
          <v-card-text
            >Gestion des entrées de feuilles de temps des
            utilisateurs</v-card-text
          >
          <v-card-actions
            ><v-btn
              color="blue"
              text
              @click="$router.push({ name: 'adminEntrees' })"
              >Accéder</v-btn
            ></v-card-actions
          >
        </v-card>
        <br />
        <br />
        <v-card elevation="2" shaped>
          <v-card-title>Utilisateurs</v-card-title>
          <v-card-text>Gestion des utilisateurs de la plateforme</v-card-text>
          <v-card-actions
            ><v-btn
              color="blue"
              text
              @click="$router.push({ name: 'AdminUtilisateurs' })"
              >Accéder</v-btn
            ></v-card-actions
          >
        </v-card>
        <br />
        <br />
                <v-card elevation="2" shaped>
          <v-card-title>Projets</v-card-title>
          <v-card-text
            >Gestion des projets et des tâches sous-jacentes</v-card-text
          >
          <v-card-actions
            ><v-btn
              color="blue"
              text
              @click="$router.push({ name: 'AdminProjets' })"
              >Accéder</v-btn
            ></v-card-actions
          >
        </v-card>
        <br />
        <br />
        <v-card elevation="2" shaped>
          <v-card-title>Projets R&D</v-card-title>
          <v-card-text
            >Gestion des affectations et des prioriés des projets R&D</v-card-text
          >
          <v-card-actions
            ><v-btn
              text
              @click="$router.push({ name: 'AdminRetD' })"
              >Affectations</v-btn
            >
            <v-btn
              text
              @click="$router.push({ name: 'AdminPrioritesProjetsRetD' })"
              >Priorités</v-btn
            ></v-card-actions
          >
        </v-card>
        <br />
        <br />
        <v-card elevation="2" shaped>
          <v-card-title>Priorités projets R&D</v-card-title>
          <v-card-text
            >Gestion de l'ordre d'affectation des heures aux projets
            R&D</v-card-text
          >
          <v-card-actions
            ><v-btn
              color="blue"
              text
              @click="$router.push({ name: 'AdminPrioritesProjetsRetD' })"
              >Priorités projets</v-btn
            ></v-card-actions
          >
        </v-card>
        <br />
        <br />
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "AdminDashboardComponent.vue",
  methods:{
    ...mapGetters(["isMainAdmin"])
  }
};
</script>