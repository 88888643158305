<template>
<v-container>
   <LoginComponent class="col-md-6" style="margin:auto"></LoginComponent>
</v-container>
</template>

<script>
import LoginComponent from "../components/LoginComponent";
export default {
  name: 'Login',
  components: {
    LoginComponent
  },
  props:["admin"]
}
</script>
