<template>
<v-container>
  <div id="feuille" class="col-md-8">
    <h1>Plateforme de gestion du temps de travail</h1>
    <hr>
    <br>
    <v-card elevation="2" shaped>
      <v-card-title>Mes feuilles de temps</v-card-title>
      <v-card-text>Accédez à votre feuille de temps de la période courante et à celles de periodes précédentes</v-card-text>
      <v-card-actions><v-btn color="blue" text @click="$router.push({name:'Feuilles de temps'})">Accéder</v-btn></v-card-actions>
    </v-card>
    <br>
    <br>
     <v-card elevation="2" shaped>
      <v-card-title>Tableau de bord</v-card-title>
      <v-card-text>Ayez une meilleure vue d'ensemble sur votre travail à travers des visualisations et des métriques</v-card-text>
      <v-card-actions><v-btn color="blue" text @click="$router.push({name:'Dashboard'})">Accéder</v-btn></v-card-actions>
    </v-card>
  </div>
</v-container>
</template>

<script>
export default {
  name: "FeuilleComponent",
  props: ["user"],
};
</script>

<style scoped>
#feuille
{
  margin: 0 auto;
}
</style>
