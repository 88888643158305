<template>
    <TimesheetComponent></TimesheetComponent>
</template>

<script>
import TimesheetComponent from '../components/TimesheetComponent.vue'
export default {
    name:"Timesheet",
    components:{TimesheetComponent}
}
</script>