import Vuex from "vuex"
import Vue from "vue"
import authentication from "./modules/authentication/authentication"
import projets from "./modules/timesheet/projets"
import entrees from "./modules/timesheet/entrees"
import retd from "./modules/timesheet/retd"
import salaires from "./modules/timesheet/salaires"
import timesheets from "./modules/timesheet/timesheets"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: { authentication, projets, entrees, retd, salaires, timesheets }
})