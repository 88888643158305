<template>
  <div>
    <v-data-table v-if="dataFetched" :items="users" :headers="_headers" :search="search" :custom-filter="searchFilter"
      locale="fr" no-data-text="Aucune donnée disponible" no-results-text="Aucun résultat correspondant" :footer-props="{
        'items-per-page-text': 'Lignes par page:',
        'page-text': '{0}-{1} sur {2}',
        'items-per-page-all-text': 'Toutes'
      }">
      <template v-slot:top>
        <v-text-field v-model="search" label="Recherche"></v-text-field>
      </template>

      <template #item.projets="{ item }">
        <div class="d-flex flex-column">
          <v-chip style="margin: 5px auto" v-for="projet in item.projets" :key="projet.id">{{ projet.nom_projet }}
          </v-chip>
        </div>
      </template>
      <template #item.type="{ item }">
        <td>
          {{ getTypeUtilisateur(item) }}
        </td>
      </template>

      <template #item.approbateur_fdt="{ item }">
        <td>
          {{ getUsername(item.approbateur_fdt) }}
        </td>
      </template>

      <template #item.etat_compte="{ item }">
        <td>
          <v-btn small v-show="!item.is_active" color="success" @click="activateUserAction(item)">Activer</v-btn>
          <v-btn small v-show="item.is_active" color="error" @click="deactivateUserAction(item)">Désactiver</v-btn>
        </td>
      </template>

      <template #item.actions="{ item }">
        <td v-if="allowUserControls(item)">
          <UserFormComponent titre="Modifier utilisateur" :edit="true" :user_id="item.id"></UserFormComponent>
          <v-btn icon color="red" @click="deleteAction(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>
    <UserFormComponent btn_text="Ajouter Utilisateur" titre="Nouvel utilisateur" :edit="false"></UserFormComponent>
    <ConfirmationPromptComponent ref="confirmationPrompt" titre="Confirmer suppression" v-bind:text="promptText"
      v-bind:callback="promptCallback"></ConfirmationPromptComponent>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserFormComponent from "./UserFormComponent.vue";
import ConfirmationPromptComponent from "./Dialog/ConfirmationPromptComponent.vue";
export default {
  name: "UserTableComponent",
  components: {
    UserFormComponent,
    ConfirmationPromptComponent,
  },
  data: () => ({
    search: "",
    dataFetched: false,
    promptText: "",
    promptCallback: null,
    headers: null,
  }),
  computed: {
    users() {
      return this.getUsers();
    },
    _headers() {
      return this.headers.filter((h) => h.show);
    },
  },
  methods: {
    ...mapActions([
      "fetchUsers",
      "fetchUserAffectationsProjets",
      "setUsers",
      "activateUser",
      "deactivateUser",
      "deleteUser",
    ]),
    ...mapGetters([
      "getUserFromUsersList",
      "getUsers",
      "isMainAdmin",
      "isSecondaryAdmin",
      "isUserMainAdmin",
      "isUserMainOrSecondaryAdmin",
    ]),
    searchFilter(value, search, entree) {
      let inNom = RegExp(search, "i").test(entree.nom);
      let inPrenom = RegExp(search, "i").test(entree.prenom);
      let inEmail = RegExp(search, "i").test(entree.email);
      return inNom || inEmail || inPrenom;
    },
    activateUserAction(user) {
      this.activateUser(user)
    },
    deactivateUserAction(user) {
      this.deactivateUser(user)
    },
    deleteAction(user) {
      this.promptText =
        "Supprimer l'utilisateur " + user.prenom + " " + user.nom + " ?";
      this.promptCallback = () => {
        this.deleteUser(user);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
    allowUserControls(utilisateur) {
      var isMain = this.isMainAdmin();
      if (this.isUserMainOrSecondaryAdmin()(utilisateur)) {
        return isMain;
      } else return true;
    },
    getUsername(utilisateur_id) {
      let user = this.users.find((u) => u.id == utilisateur_id);
      if (user == undefined) return "";
      else return user.prenom + " " + user.nom;
    },
    getTypeUtilisateur(utilisateur) {
      var result = "";
      if (utilisateur.is_staff) {
        if (utilisateur.is_main_admin) {
          result = "Administrateur Principal";
        } else {
          result = "Administrateur Secondaire";
        }
      } else {
        result = "Utilisateur";
      }
      return result;
    },
  },
  created() {
    this.headers = [
      {
        text: "Nom",
        value: "nom",
        show: true,
      },
      {
        text: "Prénom",
        value: "prenom",
        show: true,
      },
      { text: "Email", value: "email", show: true },
      { text: "Projets R&D", value: "projets", show: true },
      // {
      //   text: "Taux horaire",
      //   value: "taux_horaire",
      //   show: this.isMainAdmin(),
      // },
      {
        text: "Approbateur feuilles de temps",
        value: "approbateur_fdt",
        show: true,
      },
      { text: "Type utilisateur", value: "type", show: true },
      { text: "Etat compte", value: "etat_compte", show: true },
      { text: "Actions", value: "actions", sortable: false, show: true },
    ];
    this.fetchUsers().then((users) => {
      Promise.all(
        users.map(async (u) => {
          var projets = await this.fetchUserAffectationsProjets(u.id);
          return {
            ...u,
            projets: projets,
          };
        })
      ).then((usersWithProjects) => {
        this.setUsers(usersWithProjects);
        this.dataFetched = true;
      });
    });
  },
};
</script>