<template>
  <div>
    <div>
      <v-switch label="Afficher les projets R&D" v-model="showRetDProjets"></v-switch>
    </div>
    <v-data-table
      :items="projetsItems"
      :headers="headers"
      :search="search"
      :custom-filter="searchFilter"
      :single-expand="false"
      :expanded.sync="expanded"
      show-expand
      locale="fr"
      disable-pagination
      no-data-text="Aucune donnée disponible"
      no-results-text="Aucun résultat correspondant"
      :footer-props="{
        'items-per-page-text': 'Lignes par page:',
        'page-text': '{0}-{1} sur {2}',
        'items-per-page-all-text': 'Toutes',
      }"
    >
      <template v-slot:top>
        <v-text-field v-model="search" label="Recherche"></v-text-field>
      </template>
      <template v-slot:expanded-item="{ item }">
        <div class="expandedRow">
          <h3>Tâches</h3>
          <div class="tachesChips">
          <v-chip v-for="tache in item.taches" :key="tache.id">
            {{ tache.nom_tache }}
            <div v-if="!item.rEtD" style="float:right">
              <TacheFormComponent
                titre="Modifier tâche"
                :tache_id="tache.id"
                :projet_id="item.id"
                :edit="true"
              ></TacheFormComponent>
              <v-btn small color="red" icon @click="deleteTacheBtnClick(tache)"><v-icon>mdi-delete</v-icon></v-btn>
            </div>
          </v-chip>
          </div>
        </div>
        <TacheFormComponent
          v-if="!item.rEtD"
          titre="Ajouter tâche"
          :projet_id="item.id"
          :edit="false"
        ></TacheFormComponent>
      </template>
      <template #item.actions="{ item }">
        <td>
          <ProjetFormComponent
            titre="Modifier projet"
            :projet_id="item.id"
            :edit="true"
          ></ProjetFormComponent>
          <v-btn small color="red" icon @click="deleteProjetBtnClick(item)"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </td>
      </template>
    </v-data-table>
    <div>
      <ProjetFormComponent
        titre="Ajouter projet"
        :edit="false"
      ></ProjetFormComponent>
    </div>
    <ConfirmationPromptComponent
      ref="confirmationPrompt"
      titre="Confirmer suppression"
      v-bind:text="promptText"
      v-bind:callback="promptCallback"
    ></ConfirmationPromptComponent>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ConfirmationPromptComponent from "./Dialog/ConfirmationPromptComponent.vue";
import ProjetFormComponent from "./ProjetFormComponent.vue";
import TacheFormComponent from "./TacheFormComponent.vue";
export default {
  name: "ProjetTableComponent",
  components: {
    ConfirmationPromptComponent,
    ProjetFormComponent,
    TacheFormComponent,
  },
  computed:{
    projetsItems(){
      return this.projets.reduce((accumulator,projet)=>{
        if(!projet.rEtD)
        {
          accumulator.push(projet)
        }
        else
        {
          if(this.showRetDProjets)
            accumulator.push(projet)
        }
        return accumulator
      },[])
    }
  },
  data: () => ({
    search: "",
    projets: [],
    showRetDProjets:false,
    expanded: [],
    headers: [
      {
        text: "Projet",
        value: "nom_projet",
      },
      { text: "Actions", value: "actions", sortable: false, width: "400px" },
    ],
    promptCallback: null,
    promptText: "",
  }),
  methods: {
    ...mapActions(["fetchProjets", "deleteProjet","deleteTache"]),
    searchFilter(value, search, projet) {
      let inNom = RegExp(search, "i").test(projet.nom_projet);
      return inNom;
    },
    deleteProjetBtnClick(item) {
      this.promptText = "Supprimer le projet " + item.nom_projet + " ?";
      this.promptCallback = () => {
        this.deleteProjet(item);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
    deleteTacheBtnClick(item) {
      this.promptText = "Supprimer la tâche " + item.nom_tache + " ?";
      this.promptCallback = () => {
        this.deleteTache(item);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
  },
  created() {
    this.fetchProjets().then((p) => {
      this.projets = p;
    });
  },
};
</script>

<style scoped>
.tachesChips
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tachesChips .v-chip
{
  margin:5px auto; 
}
</style>