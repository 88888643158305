<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small v-bind="attrs" v-on="on" v-if="!edit">{{ btn_text }}</v-btn>
      <v-btn small v-bind="attrs" v-on="on" icon v-if="edit"
        ><v-icon>mdi-pencil-outline</v-icon></v-btn
      >
    </template>
    <v-card>
      <v-card-title>{{ titre }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="userForm" @submit.prevent="saveForm">
            <div>
              <div v-if="$v.nom.$dirty">
                <v-alert class="error" v-if="!$v.nom.required">
                  Ce champ est requis
                </v-alert>
              </div>
              <v-text-field
                v-model.trim="$v.nom.$model"
                label="Nom"
              ></v-text-field>
            </div>
            <div>
              <div v-if="$v.prenom.$dirty">
                <v-alert class="error" v-if="!$v.prenom.required">
                  Ce champ est requis
                </v-alert>
              </div>
              <v-text-field
                v-model.trim="$v.prenom.$model"
                label="Prénom"
              ></v-text-field>
            </div>
            <div>
              <div v-if="$v.email.$dirty">
                <v-alert class="error" v-if="!$v.email.required">
                  Ce champ est requis
                </v-alert>
                <v-alert class="error" v-if="!$v.email.email">
                  Veuillez entrer une adresse mail valide
                </v-alert>
              </div>
              <v-text-field
                v-model="$v.email.$model"
                label="Adresse mail"
              ></v-text-field>
            </div>
            <!-- <div v-if="isMainAdmin()">
              <v-text-field
                label="Taux horaire"
                type="number"
                v-model="taux_horaire"
              ></v-text-field>
            </div> -->
            <div>
              <div v-if="$v.type_utilisateur.$dirty">
                <v-alert class="error" v-if="!$v.type_utilisateur.required">
                  Ce champ est requis
                </v-alert>
              </div>
              <v-select
                label="Type d'utilisateur"
                v-model="$v.type_utilisateur.$model"
                :items="type_utilisateur_items"
              ></v-select>
            </div>
            <div>
              <v-select
                label="Approbateur des feuilles de temps"
                v-model="approbateur"
                :items="utilisateurs_approbateurs_items"
                item-text="nom_complet"
                item-value="id"
              ></v-select>
              <v-btn icon @click="approbateur = ''"
                ><v-icon>mdi-close-circle</v-icon></v-btn
              >
            </div>
            <div>
              <div v-if="edit">
                <v-switch
                  v-model="passwordSwitch"
                  label="Mettre a jour le mot de passe"
                  >Changer mot de passe</v-switch
                >
              </div>
              <div v-if="showPasswordInputs()">
                <div>
                  <div v-if="$v.motDePasse.$dirty">
                    <div class="error" v-if="!$v.motDePasse.required">
                      Ce champ est requis
                    </div>
                    <div class="error" v-if="!$v.motDePasse.minLength">
                      Le mot de passe doit contenir au moins 4 caractères
                    </div>
                  </div>
                  <v-text-field
                    v-model="$v.motDePasse.$model"
                    :type="passwordVisible ? 'text' : 'password'"
                    :append-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="passwordVisible = !passwordVisible"
                    label="Mot de passe"
                  ></v-text-field>
                </div>
                <div>
                  <div v-if="$v.cMotDePasse.$dirty">
                    <div class="error" v-if="!$v.cMotDePasse.required">
                      Ce champ est requis
                    </div>
                    <div class="error" v-if="!$v.cMotDePasse.minLength">
                      Le mot de passe doit contenir au moins 4 caractères
                    </div>
                    <div class="error" v-if="!$v.cMotDePasse.sameAsPassword">
                      Les deux mots de passe doivent correspondre
                    </div>
                  </div>
                  <v-text-field
                    v-model="$v.cMotDePasse.$model"
                    :type="cPasswordVisible ? 'text' : 'password'"
                    :append-icon="cPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="cPasswordVisible = !cPasswordVisible"
                    label="Confirmer mot de passe"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <v-row
              ><v-col> <v-btn @click="close">Fermer</v-btn></v-col>
              <v-col><v-btn type="submit">Enregistrer</v-btn></v-col></v-row
            >
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  required,
  requiredIf,
  email,
  minLength,
  sameAs,
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "UserFormComponent",
  props: ["user_id", "btn_text", "titre", "edit"],
  computed: {
    type_utilisateur_items() {
      if (this.isMainAdmin()) {
        return [
          "Utilisateur",
          "Administrateur secondaire",
          "Administrateur principal",
        ];
      } else {
        return ["Utilisateur"];
      }
    },
    utilisateurs_approbateurs_items() {
      let users = this.getUsers();
      if (users != null) {
        return users.filter((u) => u.is_staff);
      } else {
        return [];
      }
    },
  },
  data: () => ({
    dialog: false,
    user: null,
    nom: "",
    prenom: "",
    email: "",
    type_utilisateur: "",
    approbateur: "",
    //taux_horaire: 0,
    passwordSwitch: false,
    passwordVisible: false,
    cPasswordVisible: false,
    motDePasse: "",
    cMotDePasse: "",
    editPassword: false,
  }),
  validations: {
    nom: {
      required,
    },
    prenom: {
      required,
    },
    email: {
      required,
      email,
    },
    type_utilisateur: {
      required,
    },
    motDePasse: {
      required: requiredIf(function () {
        return this.showPasswordInputs();
      }),
      minLength: minLength(4),
    },
    cMotDePasse: {
      required: requiredIf(function () {
        return this.showPasswordInputs();
      }),
      minLength: minLength(4),
      sameAsPassword: sameAs("motDePasse"),
    },
  },
  watch: {
    dialog(value) {
      if (value) {
        this.open();
      } else {
        this.close();
      }
    },
  },
  methods: {
    ...mapActions([
      "registerUser",
      "registerMainAdminUser",
      "registerSecondaryAdminUser",
      "updateUser",
      "updateUserPassword",
      "updateApprobateurFDT",
      "addApprobateurFDT",
      "deleteApprobateurFDT"
    ]),
    ...mapGetters([
      "getUsers",
      "getUserFromUsersList",
      "isMainAdmin",
      "isUserMainAdmin",
      "isUserSecondaryAdmin",
      "isUserAdmin",
    ]),
    editLoadUser() {
      this.nom = this.user.nom;
      this.prenom = this.user.prenom;
      this.email = this.user.email;
      //this.taux_horaire = this.user.taux_horaire;
      if (this.isUserMainAdmin()(this.user)) {
        this.type_utilisateur = this.type_utilisateur_items[2];
      } else if (this.isUserSecondaryAdmin()(this.user)) {
        this.type_utilisateur = this.type_utilisateur_items[1];
      } else if (!this.isUserAdmin()(this.user)) {
        this.type_utilisateur = this.type_utilisateur_items[0];
      }
      this.approbateur = this.user.approbateur_fdt;
    },
    saveForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (!this.$props.edit) {
          this.addNew();
        } else {
          this.update();
        }
        this.close();
      }
    },
    addNew() {
      var user = {
        nom: this.nom,
        prenom: this.prenom,
        email: this.email,
        //taux_horaire: this.taux_horaire,
        compagnie: this.compagnie,
        password: this.motDePasse,
      };

      let approbateur = this.approbateur;
      var callback = (user) => {
        if (user != null) {
          if (approbateur != "") {
            var approbation = {
              utilisateur: user.id,
              approbateur: approbateur,
            };
            this.addApprobateurFDT(approbation);
          }
        }
      };

      switch (this.type_utilisateur) {
        case this.type_utilisateur_items[0]: {
          this.registerUser(user).then(callback);
          break;
        }
        case this.type_utilisateur_items[1]: {
          this.registerSecondaryAdminUser(user).then(callback);
          break;
        }
        case this.type_utilisateur_items[2]: {
          this.registerMainAdminUser(user).then(callback);
          break;
        }
      }

      this.close();
    },
    showPasswordInputs() {
      if (!this.edit) return true;
      else {
        return this.passwordSwitch;
      }
    },
    update() {
      var user = {
        id: this.user.id,
        nom: this.nom,
        prenom: this.prenom,
        email: this.email,
        //taux_horaire: this.taux_horaire,
      };
      switch (this.type_utilisateur) {
        case this.type_utilisateur_items[0]: {
          user.is_staff = false;
          user.is_main_admin = false;
          break;
        }
        case this.type_utilisateur_items[1]: {
          user.is_staff = true;
          user.is_main_admin = false;
          break;
        }
        case this.type_utilisateur_items[2]: {
          user.is_staff = true;
          user.is_main_admin = true;
          break;
        }
      }

      var approbateur=this.approbateur
      var currentUser=this.user
      this.updateUser(user).then((result) => {
        if (result) {
            var approbation = {
                utilisateur: currentUser.id,
                approbateur: approbateur,
              };
          if (currentUser.approbateur_fdt == "") {
            if (approbateur != "") {
              this.addApprobateurFDT(approbation);
            }
          }
          else
          {
            if (approbateur != "") {
              this.updateApprobateurFDT(approbation);
            }
            else
            {
              this.deleteApprobateurFDT(approbation)
            }
          }
        }
      });
      if (this.passwordSwitch) {
        var data = { new_password: this.motDePasse, user_id: this.user.id };
        this.updateUserPassword(data);
      }
    },
    close() {
      this.$refs.userForm.reset();
      this.$v.$reset();
      this.dialog = false;
    },
    open() {
      if (this.edit) {
        this.user = this.getUserFromUsersList()(this.user_id);
        this.editLoadUser();
      }
    },
  },
};
</script>