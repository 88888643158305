import axios from "axios"
import { feuillesUrl, feuillesPeriodeCouranteUrl,feuilleTemplateDeleteUrl,feuilleTemplateUrl, feuilleExcelUrl, periodeFeuillesUrl, feuilleUrl, feuilleCouranteUrl, soumettreFeuilleUrl, approuverFeuilleUrl, rejeterFeuilleUrl, periodeUrl, periodesUrl,periodeCouranteUrl, userApprobationsUrl, userFeuillePeriodeUrl } from "../urls"

const state = {
    feuillesDeTemps: null,
    feuilleCourante: null,
    feuillePrecedente: null,
    periodeCourante: null,
    periodes: null,
    periode: null,
    userApprobations: null,
}

const getters = {
    getFeuillesDeTemps: (state) => {
        return state.feuillesDeTemps
    },
    getFeuilleCourante: (state) => {
        return state.feuilleCourante
    },
    getPeriodeCourante: (state) => {
        return state.periodeCourante
    },

    //eslint-disable-next-line no-unused-vars
    isApprouve: (state) => (statut) => {
        return statut == "Approuvé"
    },

    //eslint-disable-next-line no-unused-vars
    isBrouillon: (state) => (statut) => {
        return statut == "Brouillon"
    },

    //eslint-disable-next-line no-unused-vars
    isSoumis: (state) => (statut) => {
        return statut == "Soumis"
    },

    //eslint-disable-next-line no-unused-vars
    isEnAttente: (state) => (statut) => {
        return statut == "En attente de correction"
    },

    //eslint-disable-next-line no-unused-vars
    getStatutClass: (state, getters) => (statut) => {
        if (getters.isBrouillon(statut) | getters.isEnAttente(statut)) {
            return "text-red"
        } else if (getters.isApprouve(statut)) {
            return "text-green"
        } else if (getters.isSoumis(statut)) {
            return "text-blue"
        }
    }
}

const actions = {
    async fetchFeuillesDeTemps({ commit }, user_id) {
        var url = feuillesUrl.replace("{id}", user_id);
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setFeuilles", reponse.data)
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    async fetchFeuillesDeTempsPeriodeCourante({ commit }) {
        const reponse = await axios.get(feuillesPeriodeCouranteUrl).then((reponse) => {
            if (reponse.status == 200) {
                commit("setFeuilles", reponse.data)
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    async fetchFeuillesDeTempsPeriode({ commit }, periode_id) {
        var url = periodeFeuillesUrl.replace("{id}", periode_id);
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setFeuilles", reponse.data)
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    async fetchUserFeuilleDeTempsPeriode({ commit }, data) {
        let id_user = data.id_user;
        let id_periode = data.id_periode
        var url = userFeuillePeriodeUrl.replace("{id_periode}", id_periode);
        url = url.replace("{id_user}", id_user);
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                if (reponse.data.feuille_courante)
                    commit("setFeuilleCourante", reponse.data)
                else
                    commit("setFeuillePrecedente", reponse.data)
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    async fetchFeuilleCourante({ commit }, user_id) {
        var url = feuilleCouranteUrl.replace("{id}", user_id);
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setFeuilleCourante", reponse.data)
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    async fetchFeuilleById({ commit }, feuille_id) {
        var url = feuilleUrl.replace("{id}", feuille_id);
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                if (reponse.data.feuille_courante)
                    commit("setFeuilleCourante", reponse.data)
                else
                    commit("setFeuillePrecedente", reponse.data)
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    //eslint-disable-next-line no-unused-vars
    async fetchFeuilleExcel({ commit }, feuille_id) {
        var url = feuilleExcelUrl.replace("{id}", feuille_id);
        const reponse = await axios({ url: url, method: "GET", responseType: "blob" }).then((reponse) => {
            if (reponse.status == 200) {
                let blob = new Blob([reponse.data], { type: "application/xlsx" });
                const url = window.URL.createObjectURL(blob);
                return url
            }
            return null
        }).catch((error) => {
            console.log(error)
            return null
        });
        return reponse;
    },

    async fetchUserApprobations({ commit }, user_id) {
        var url = userApprobationsUrl.replace("{id}", user_id);
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setUserApprobations", reponse.data)
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    async fetchPeriode({ commit }, periode_id) {
        var url = periodeUrl.replace("{id}", periode_id);
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setPeriodeCourante", reponse.data)
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    async fetchPeriodeCourante({ commit }) {
        return await axios.get(periodeCouranteUrl).then((reponse) => {
            if (reponse.status == 200) {
                commit("setPeriode", reponse.data)
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
    },

    async fetchPeriodes({ commit }) {
        const reponse = await axios.get(periodesUrl).then((reponse) => {
            if (reponse.status == 200) {
                commit("setPeriodes", reponse.data)
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    async createFeuillePeriodeCourante({ commit }, user_id) {
        var url = feuilleCouranteUrl.replace("{id}", user_id);
        const reponse = await axios.post(url).then((reponse) => {
            if (reponse.status == 201) {
                commit("setFeuilleCourante", reponse.data)
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },


    async soumettreFeuilleDeTemps({ commit }, data) {
        var url = soumettreFeuilleUrl.replace("{id}", data.feuille_id)
        var result = await axios.put(url).then((response) => {
            if (response.status == 200) {
                switch (data.origin) {
                    case "courante":
                        {
                            commit("updateFeuilleCourante", response.data)
                            break;
                        }
                    case "precedente":
                        {
                            commit("updateFeuillePrecedente", response.data)
                            break;
                        }
                    case "liste":
                        {
                            commit("updateFeuilleFromList", response.data)
                            break;
                        }
                }
                return true
            } else
                return false
        }).catch((error) => {
            console.log(error.data)
            return false
        });
        return result
    },

    async approuverFeuilleDeTemps({ commit }, data) {
        var url = approuverFeuilleUrl.replace("{id}", data.feuille_id)
        var result = await axios.put(url).then((response) => {
            if (response.status == 200) {
                switch (data.origin) {
                    case "courante":
                        {
                            commit("updateFeuilleCourante", response.data)
                            break;
                        }
                    case "precedente":
                        {
                            commit("updateFeuillePrecedente", response.data)
                            break;
                        }
                    case "liste":
                        {
                            commit("updateFeuilleFromList", response.data)
                            break;
                        }
                }
                return true
            } else
                return false
        }).catch((error) => {
            console.log(error.data)
            return false
        });
        return result
    },

    async rejeterFeuilleDeTemps({ commit }, data) {
        var url = rejeterFeuilleUrl.replace("{id}", data.feuille_id)
        var result = await axios.put(url).then((response) => {
            if (response.status == 200) {
                switch (data.origin) {
                    case "courante":
                        {
                            commit("updateFeuilleCourante", response.data)
                            break;
                        }
                    case "precedente":
                        {
                            commit("updateFeuillePrecedente", response.data)
                            break;
                        }
                    case "liste":
                        {
                            commit("updateFeuilleFromList", response.data)
                            break;
                        }
                }
                return true
            } else
                return false
        }).catch((error) => {
            console.log(error.data)
            return false
        });
        return result
    },

     //eslint-disable-next-line no-unused-vars
    async saveFeuilleAsTemplate({ commit }, feuille_id) {
        var url = feuilleTemplateUrl.replace("{id}", feuille_id);
        return await axios.post(url).then((reponse) => {
            if (reponse.status == 201) {
                return true
            }
            return false
        }).catch((error) => {
            console.log(error.data)
            return false
        });
    },


     //eslint-disable-next-line no-unused-vars
    async deleteFeuilleTemplate({ commit }) {
        return await axios.delete(feuilleTemplateDeleteUrl).then((reponse) => {
            if (reponse.status == 200) {
                return true
            }
            return false
        }).catch((error) => {
            console.log(error.data)
            return false
        });
    },

    setFeuilleCourante: ({ commit }, feuilleCourante) => {
        commit("setFeuilleCourante", feuilleCourante)
    },

}
const mutations = {
    setFeuilles: (state, feuilles) => {
        state.feuillesDeTemps = feuilles
    },
    setFeuilleCourante: (state, feuilleCourante) => {
        state.feuilleCourante = feuilleCourante
    },
    setFeuillePrecedente: (state, feuillePrecedente) => {
        state.feuillePrecedente = feuillePrecedente
    },
    setPeriodeCourante: (state, periodeCourante) => {
        state.periodeCourante = periodeCourante
    },
    setPeriodes: (state, periodes) => {
        state.periodes = periodes
    },
    setUserApprobations: (state, approbations) => {
        state.userApprobations = approbations
    },
    addFeuille: (state, feuille) => {
        state.feuillesDeTemps.push(feuille)
    },
    updateFeuilleCourante: (state, updatedFeuille) => {
        var feuille = state.feuilleCourante;
        feuille.statut = updatedFeuille.statut
        feuille.date_soumission = updatedFeuille.date_soumission
        feuille.date_approbation = updatedFeuille.date_approbation
    },

    updateFeuilleCouranteTemplate: (state, newValue) => {
        state.feuilleCourante.has_template=newValue;
    },

    updateFeuillePrecedente: (state, updatedFeuille) => {
        var feuille = state.feuillePrecedente;
        feuille.statut = updatedFeuille.statut
        feuille.date_soumission = updatedFeuille.date_soumission
        feuille.date_approbation = updatedFeuille.date_approbation
    },

    updateFeuilleFromList: (state, updatedFeuille) => {
        let feuilleIndex = state.feuillesDeTemps.findIndex(f => f.id == updatedFeuille.id)
        if (feuilleIndex != -1) {
            var feuille = state.feuillesDeTemps[feuilleIndex];
            feuille.statut = updatedFeuille.statut
            feuille.date_soumission = updatedFeuille.date_soumission
            feuille.date_approbation = updatedFeuille.date_approbation
        }
    },



    // deleteEntree: (state, entree) => {
    //     var entreeIndex = state.entrees.findIndex(e => e.id == entree.id);
    //     state.entrees.splice(entreeIndex, 1);
    // }
}

export default {
    state,
    getters,
    actions,
    mutations
}