import Vue from 'vue'
import Vuetify from 'vuetify';
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import vuelidate from 'vuelidate'
import { DateTime } from 'luxon'
import ApexCharts from 'vue-apexcharts'
import VueApexCharts from 'vue-apexcharts';
import setupAxios from './axios_interceptor/interceptor'

Vue.config.productionTip = false
Vue.prototype.$datetime = DateTime;
Vue.use(Vuetify)
Vue.use(router)
Vue.use(ApexCharts)
Vue.use(vuelidate)
Vue.component("apexchart", VueApexCharts)

setupAxios()

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')