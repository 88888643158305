<template>
  <v-dialog v-model="dialog" persistent width="400px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small v-bind="attrs" v-on="on" v-if="!edit"
        >Ajouter projet R&D</v-btn
      >
      <v-btn small v-bind="attrs" v-on="on" icon v-if="edit"
        ><v-icon>mdi-pencil-outline</v-icon></v-btn
      >
    </template>
    <v-card v-if="dataFetched">
      <v-card-title>{{ titre }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="prioriteForm" @submit.prevent="saveForm">
            <div>
              <div v-if="$v.projetSelect.$dirty">
                <v-alert class="error" v-if="!$v.projetSelect.required">
                  Ce champ est requis
                </v-alert>
              </div>
              <v-select
                :items="projetsRetDdisponibles"
                v-model="$v.projetSelect.$model"
                item-value="id"
                item-text="nom_projet"
                label="Projet R&D"
                no-data-text="Aucun projet disponible"
              ></v-select>
            </div>
            <div>
              <div v-if="$v.priorite.$dirty">
                <v-alert class="error" v-if="!$v.priorite.required">
                  Ce champ est requis
                </v-alert>
              </div>
              <div v-if="$v.priorite.$dirty">
                <v-alert
                  class="error"
                  v-if="!$v.priorite.isPrioriteUniqueValidator"
                >
                  L'indice de priorité doit être unique
                </v-alert>
              </div>
              <v-text-field
                type="number"
                min="0"
                v-model="$v.priorite.$model"
                label="priorite"
              ></v-text-field>
            </div>
            <v-row>
              <v-col><v-btn type="submit">Enregistrer</v-btn></v-col>
              <v-col> <v-btn @click="close">Fermer</v-btn></v-col></v-row
            >
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
function isPrioriteUniqueValidator() {
  return this.isPrioriteUnique;
}
export default {
  name: "PrioriteProjetsRetDForm",
  props: ["priorite_id", "btn_text", "titre", "edit"],
  data: () => ({
    dialog: false,
    dataFetched: false,
    projetSelect: null,
    prioriteRetD: null,
    projetsRetD: null,
    priorite: null,
  }),
  validations: {
    projetSelect: {
      required,
    },
    priorite: {
      required,
      isPrioriteUniqueValidator,
    },
  },
  watch: {
    dialog(value) {
      if (value) {
        this.open();
      } else {
        this.close();
      }
    },
  },
  computed: {
    projetsRetDdisponibles() {
      if (this.dataFetched) {
        let id_projets_presents = this.getPriorites().map((p) => {
          return p.projet;
        });
        return this.projetsRetD.reduce((accumulator, p) => {
          if (!id_projets_presents.includes(p.id)) {
            accumulator.push(p);
          }
          return accumulator;
        }, []);
      }
      return null;
    },
    isPrioriteUnique() {
      if (this.priorite != null) {
        let indice_priorite_presents = this.getPriorites().map((p) => {
          return p.priorite;
        });
        return !indice_priorite_presents.includes(parseInt(this.priorite));
      }
      return true;
    },
  },
  methods: {
    ...mapGetters(["getPriorites"]),
    ...mapActions([
      "addPriorite",
      "updatePriorite",
      "fetchPriorite",
      "fetchProjetsRetD",
      "deletePriorite",
    ]),
    editLoadPriorite() {
      var prioriteRetD = this.prioriteRetD;
      this.priorite = prioriteRetD.priorite;
      this.projetSelect = prioriteRetD.projet;
    },
    saveForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (!this.$props.edit) {
          this.addNew();
        } else {
          this.update();
        }
        this.close();
      }
    },
    addNew() {
      var priorite = {
        projet: this.projetSelect,
        priorite: this.priorite,
      };
      this.addPriorite(priorite);
      this.close();
    },
    update() {
      var priorite = {
        id: this.prioriteRetD.id,
        projet: this.projetSelect,
        priorite: this.priorite,
      };
      this.updatePriorite(priorite);
    },
    close() {
      this.$refs.prioriteForm.reset();
      this.$v.$reset();
      this.dialog = false;
    },
    open() {
      this.fetchProjetsRetD().then((projets) => {
        this.projetsRetD = projets;
        if (this.$props.edit == true) {
          this.fetchPriorite().then((priorite) => {
            this.prioriteRetD = priorite;
            this.editLoadPriorite();
          });
        }
        this.dataFetched = true;
      });
    },
  },
};
</script>