<template>
  <div>
    <v-text-field v-model="search" label="Recherche"></v-text-field>
    <v-row>
      <v-col>
        <v-select
          label="Projets"
          v-model="projetSelect"
          :items="projetsList"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          label="Tâches"
          v-model="tacheSelect"
          :items="tachesList"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          label="Utilisateurs"
          v-model="userSelect"
          :items="usersList"
        ></v-select>
      </v-col>
      <v-col class="dateFilter">
        <v-select
          v-model="periodeSelect"
          :items="periodeFilterItems"
        ></v-select>
        <v-row v-show="isPeriodePersonaliseeSelected">
          <DatePickerComponent
            label="Date début"
            v-on:dateSaved="setDateDebut"
            :previousDate="dateDebutFilter"
          ></DatePickerComponent>
          <DatePickerComponent
            label="Date fin"
            v-on:dateSaved="setDateFin"
            :previousDate="dateFinFilter"
          ></DatePickerComponent>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DatePickerComponent from "@/components/Dialog/DatePickerComponent.vue";
import { mapActions } from "vuex";
export default {
  name: "EntreesFilterComponent",
  components: { DatePickerComponent },
  data: () => ({
    projetSelect: "",
    tacheSelect: "",
    userSelect: "",
    dateDebutFilter: null,
    dateFinFilter: null,
    periodeCourante:null,
    search: "",
    noOptionSelectedString: "Tous",
    periodeSelect: null,
    periodeFilterItems: [
      "Derniere période de paie",
      "Dernier mois",
      "Dernier trois mois",
      "Sélection personalisée",
    ],
    filterFields: [
      "nom_projet",
      "nom_tache",
      "nom_utilisateur",
    ],
  }),
  methods: {
    ...mapActions(["fetchPeriodeCourante"]),
    getEntreesMetadataFilterList(metadata_name) {
      return this.entrees.reduce(
        (acc, entree) => {
          let metadata = entree[metadata_name];
          if (!acc.includes(metadata)) acc.push(metadata);
          return acc;
        },
        [this.noOptionSelectedString]
      );
    },
    getRangeDernierMois() {
      let datetime = this.$datetime;
      let date_fin = datetime.now();
      let date_debut = date_fin.minus({ months: 1 });
      return {
        date_debut: date_debut.toFormat("yyyy-MM-dd"),
        date_fin: date_fin.toFormat("yyyy-MM-dd"),
      };
    },
    getRangeDernierePeriode() {
      let date_debut=this.periodeCourante.date_debut
      let date_fin=this.periodeCourante.date_fin
      return {date_debut:date_debut,date_fin:date_fin}
    },
    getRangeDernierTroisMois() {
      let datetime = this.$datetime;
      let date_fin = datetime.now();
      let date_debut = date_fin.minus({ months: 3 });
      return {
        date_debut: date_debut.toFormat("yyyy-MM-dd"),
        date_fin: date_fin.toFormat("yyyy-MM-dd"),
      };
    },
    getRangePersonalise() {
      if (this.dateDebutFilter == null && this.dateFinFilter == null) {
        return null;
      } else {
        let datetime = this.$datetime;
        let date_fin = datetime
          .fromISO(this.dateFinFilter)
          .toFormat("yyyy-MM-dd");
        let date_debut = datetime
          .fromISO(this.dateDebutFilter)
          .toFormat("yyyy-MM-dd");
        return {
          date_debut: date_debut,
          date_fin: date_fin,
        };
      }
    },
    getSelectedPeriodeRange(selectedPeriode) {
      var range;
      if (this.isPeriodePersonaliseeSelected) {
        range = this.getRangePersonalise();
      } else {
        switch (selectedPeriode) {
          //Dernier mois
          case this.periodeFilterItems[0]: {
            range = this.getRangeDernierePeriode();
            break;
          }
          case this.periodeFilterItems[1]: {
            range = this.getRangeDernierMois();
            break;
          }
          case this.periodeFilterItems[2]: {
            range = this.getRangeDernierTroisMois();
            break;
          }
        }
      }
      return range;
    },
    setDateDebut(date) {
      this.dateDebutFilter = date;
    },
    setDateFin(date) {
      this.dateFinFilter = date;
    },
  },
  props: ["entrees","customPeriode"],
  computed: {
    projetsList() {
      return this.getEntreesMetadataFilterList(this.filterFields[0]);
    },
    tachesList() {
      return this.getEntreesMetadataFilterList(this.filterFields[1]);
    },
    usersList() {
      return this.getEntreesMetadataFilterList(this.filterFields[2]);
    },
    selectFiltersList() {
      var filters = [];
      if (this.projetSelect != this.noOptionSelectedString) {
        filters.push({ key: this.filterFields[0], value: this.projetSelect });
      }
      if (this.tacheSelect != this.noOptionSelectedString) {
        filters.push({ key: this.filterFields[1], value: this.tacheSelect });
      }
      if (this.userSelect != this.noOptionSelectedString) {
        filters.push({ key: this.filterFields[2], value: this.userSelect });
      }
      return filters;
    },
    isPeriodePersonaliseeSelected() {
      if (
        this.periodeSelect ==
        this.periodeFilterItems[this.periodeFilterItems.length - 1]
      )
        return true;
      else return false;
    },
    entreesFiltered() {
      let filters = this.selectFiltersList;
      var result = this.entrees.filter((e) => {
        var filterResults = [];

        //Selection
        if (filters.length != 0) {
          filterResults = filters.map((f) => {
            return e[f.key] == f.value;
          });
        }
        //Recherche
        //if (searchString != "") {
        //   if (this.search != "") {
        let searchString = this.search;
        var searchResults = [];
        for (var i = 0; i < this.filterFields.length; i++) {
          let searchResult = RegExp(searchString, "i").test(
            e[this.filterFields[i]]
          );
          searchResults.push(searchResult);
        }
        searchResults = searchResults.includes(true);
        filterResults.push(searchResults);
        //}

        //Fitrage par date

        if (!filterResults.includes(false)) return true;
        else return false;
      });

      return result;
    },
  },
  watch: {
    entreesFiltered(newValue) {
      this.$emit("entreesFiltered", newValue);
    },
    periodeSelect(newValue) {
      let range = this.getSelectedPeriodeRange(newValue);
      if (range != null) this.$emit("periodeChanged", range);
    },
    //eslint-disable-next-line no-unused-vars
    dateDebutFilter(newValue) {
      if (this.isPeriodePersonaliseeSelected) {
        let range = this.getRangePersonalise();
        this.$emit("periodeChanged", range);
      }
    },
    //eslint-disable-next-line no-unused-vars
    dateFinFilter(newValue) {
      if (this.isPeriodePersonaliseeSelected) {
        let range = this.getRangePersonalise();
        this.$emit("periodeChanged", range);
      }
    },
  },
  created() {
    this.projetSelect = this.noOptionSelectedString;
    this.tacheSelect = this.noOptionSelectedString;
    this.userSelect = this.noOptionSelectedString;
    if(this.customPeriode!=undefined)
    {
      this.setDateDebut(this.customPeriode.date_debut)
      this.setDateFin(this.customPeriode.date_fin)
      this.periodeSelect=this.periodeFilterItems[3]
    }
    else
      this.periodeSelect = this.periodeFilterItems[1];
      
    this.fetchPeriodeCourante().then((p)=>{
      this.periodeCourante=p
    })
  },
};
</script>