<template>
    <TimesheetSingleComponent :feuille_id="feuille_id"></TimesheetSingleComponent>
</template>

<script>
import TimesheetSingleComponent from '../components/TimesheetSingleComponent.vue'
export default {
    name:"TimesheetSingle",
    props: ["feuille_id"],
    components:{TimesheetSingleComponent}
}
</script>