<template>
    <v-dialog persistent max-width="600px" v-model="dialog">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!edit" small v-bind="attrs" v-on="on" icon><v-icon>mdi-plus-circle</v-icon></v-btn>
            <v-btn v-if="edit" x-small v-bind="attrs" v-on="on" icon><v-icon>mdi-pencil-outline</v-icon></v-btn>
        </template>
        <v-card>
            <br />
            <v-card-actions>
                <v-row>
                    <v-col>
                        <v-btn @click="saveForm">Enregistrer</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn @click="close">Fermer</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-card-title>Selectionner tâches</v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="tacheForm" @submit.prevent="saveForm">
                        <div>
                            <div v-if="$v.selected_projet.$dirty">
                                <div class="error" v-if="!$v.selected_projet.required">
                                    Ce champ est requis
                                </div>
                            </div>
                            <v-select v-model="$v.selected_projet.$model" :items="projets" item-text="nom_projet"
                                label="Projet" return-object @change="selectedProjetChanged"></v-select>
                        </div>

                        <div v-if="selected_projet != null">
                            <v-simple-table style="width:100%">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>Tâches</th>
                                            <th>Sélection</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="tache in selectedProjetTaches" :key="tache.id">
                                            <td>{{ tache.nom_tache }}</td>
                                            <td><v-checkbox :true-value="true" :false-value="false" ref="check"
                                                    @change="tacheToggled($event, tache)"
                                                    :input-value="isTacheSelectedObject[tache.id]"></v-checkbox></td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </v-form>
                </v-container>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>
  
<script>
import { required } from "vuelidate/lib/validators";
export default {
    name: "TimesheetProjetSelectComponent",
    data: () => ({
        dialog: false,
        selected_projet: null,
        selected_taches: [],
    }),
    computed: {
        selectedProjetTaches() {
            if (this.selected_projet != null) {
                return this.taches.filter((t) => t.projet == this.selected_projet.id)
            }
            else
                return []
        },
        isTacheSelectedObject() {
            return this.selectedProjetTaches.reduce((accumulator, tache) => {
                let index = this.selected_taches.findIndex((t) => t.id == tache.id)
                accumulator[tache.id] = index != -1 ? true : false
                return accumulator
            }, {})
        },
        edit() {
            return this.previously_selected_projet != undefined
        }
    },
    props: ["projets", "taches", "previously_selected_projet", "previously_selected_taches"],
    watch: {
        dialog(value) {
            if (value) {
                this.open();
            }
        },
    },
    methods: {
        async saveForm() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.saveSelectedTaches()
                this.close()
            }
        },

        selectedProjetChanged() {
            this.selected_taches = []
        },

        tacheToggled(value, tache) {
            if (value) {
                this.selected_taches.push(tache)
            }
            else {
                let index = this.selected_taches.findIndex((t) => t.id == tache.id)
                if (index != -1)
                    this.selected_taches.splice(index, 1)
            }
        },

        isTacheSelected(tache) {
            let index = this.selected_taches.findIndex((t) => t.id == tache.id)
            let result = index != -1 ? true : false
            return result
        },

        resetCheckboxes() {
            for (var key in this.$refs) {
                if (key.includes("check")) {
                    try {
                        this.$refs[key][0].value = false;
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        },


        saveSelectedTaches() {

            //Creates deep copy of the value so its not lost when form is closed
            let taches = this.selected_taches.map((_) => {
                return { ..._ }
            })
            let projet = { ...this.selected_projet }

            let payload = { projet, taches }

            this.$emit("onSave", payload)
        },


        open() {
            if (this.previously_selected_projet != undefined) {
                this.selected_projet = this.projets.find((p) => p.id == this.previously_selected_projet.id)

                if (this.previously_selected_taches != undefined) {
                    this.selected_taches = this.previously_selected_taches.map((t) => {
                        return { ...t }
                    })
                }
            }
        },
        close() {
            this.$v.$reset();
            this.selected_taches = [];
            this.selected_projet = null;
            this.dialog = false;
        },
    },
    validations: {
        selected_projet: {
            required,
        },
    },
};
</script>