<template>
  <v-dialog max-width="800px" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small v-bind="attrs" v-on="on" icon><v-icon>mdi-pencil-outline</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-title>Modifier entrée</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="entreeForm">
            <div>
              <div v-if="$v.projet.$dirty">
                <div class="error" v-if="!$v.projet.required">
                  Ce champ est requis
                </div>
              </div>
              <v-select v-model="$v.projet.$model" :items="projets" item-text="nom_projet"
                no-data-text="Aucune donnée disponible" label="Projet" return-object
                @change="projectChanged"></v-select>
            </div>
            <div v-if="projet != null">
              <div v-if="$v.tache.$dirty">
                <div class="error" v-if="!$v.tache.required">
                  Ce champ est requis
                </div>
              </div>
              <v-select v-model="$v.tache.$model" :items="selectedProjetTaches" item-text="nom_tache"
                no-data-text="Aucune donnée disponible" label="Tâche" return-object></v-select>
            </div>
            <DatePickerComponent label="Date" :previousDate="date" :minDate="getPeriodeDateDebut"
              :maxDate="getPeriodeDateFin" v-on:dateSaved="saveDate"></DatePickerComponent>
            <div>
              <div v-if="$v.nb_heures.$dirty">
                <div class="error" v-if="!$v.nb_heures.required">
                  Ce champ est requis
                </div>
                <div class="error" v-if="!$v.nb_heures.decimal">
                  Veuillez rentrer une valeur numérique
                </div>
              </div>
              <v-text-field v-model="$v.nb_heures.$model" label="Nombre d'heures" type="number" min="1"></v-text-field>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn @click="close">Fermer</v-btn>
          </v-col>
          <v-col>
            <v-btn @click="saveForm">Enregistrer</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePickerComponent from "./Dialog/DatePickerComponent.vue";
import { required, decimal } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "TimeEditComponent.vue",
  components: {
    DatePickerComponent,
  },
  data: () => ({
    dialog: false,
    projet: null,
    tache: null,
    nb_heures: null,
    date: null,
  }),
  computed: {
    periode() {
      return this.getPeriodeCourante()
    },
    selectedProjetTaches() {
      if (this.projet != null) {
        return this.taches.filter((t) => t.projet == this.projet.id)
      }
      else return null
    },
    getPeriodeDateDebut() {
      return this.periode != null ? this.periode.date_debut : ""
    },
    getPeriodeDateFin() {
      return this.periode != null ? this.periode.date_fin : ""
    }
  },
  props: ["entree", "projets", "taches"],
  watch: {
    dialog(value) {
      if (value) {
        this.open();
      } else {
        this.close();
      }
    },
  },
  methods: {
    ...mapActions(["fetchProjets", "updateEntree"]),
    ...mapGetters(["getPeriodeCourante"]),
    loadEntree() {
      var entree = this.$props.entree;
      this.nb_heures = entree.nombre_heures;
      this.date = entree.date;
      this.projet = this.projets.find(p => p.id == entree.projet)
      this.tache = this.selectedProjetTaches.find(t => t.id == entree.tache)
    },
    saveDate(new_date) {
      this.date = new_date;
    },
    projectChanged() {
      this.tache = this.selectedProjetTaches[0]
    },
    saveForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var entree = {
          id: this.entree.id,
          projet: this.projet.id,
          tache: this.tache.id,
          date: this.date,
          nombre_heures: this.nb_heures,
          utilisateur: this.entree.utilisateur,
          feuille_de_temps: this.entree.feuille_de_temps
        }
        this.$emit("onSave", entree)
        this.close();
      }
    },
    open() {
      this.loadEntree();
    },
    close() {
      this.$v.$reset();
      this.dialog = false;
    },
  },
  validations: {
    projet: {
      required,
    },
    tache: {
      required,
    },
    nb_heures: {
      required,
      decimal,
    },
    date: {
      required,
    },
  },
};
</script>