const serverUrl = "api/"

const loginUrl = serverUrl + "login/"
const logoutUrl = serverUrl + "logout/"
const affectationsUrl = serverUrl + "affectations/"
const affectationUrl = serverUrl + "affectation/{id}/"
const userInfosUrl = serverUrl + "user/infos/"
const userUrl = serverUrl + "user/{id}/"
const activateUserUrl = serverUrl + "user/{id}/activate/"
const deactivateUserUrl = serverUrl + "user/{id}/deactivate/"
const userUpdatePasswordUrl = serverUrl + "user/{id}/update_password/"
const userAffectedProjetsUrl = serverUrl + "user/{id}/affectations/projets/"
const usersUrl = serverUrl + "users/"
const registerUrl = serverUrl + "register/"
const registerMainAdminUrl = serverUrl + "register/main/"
const registerSecondaryAdminUrl = serverUrl + "register/secondary/"
const projetUrl = serverUrl + "projet/{id}/"
const projetsUrl = serverUrl + "projets/"
const projetsRetDUrl = serverUrl + "projets/retd/"
const prioritesProjetsRetDUrl = serverUrl + "projets/retd/priorites/"
const prioriteProjetsRetDUrl = serverUrl + "projets/retd/priorite/{id}/"
const tachesUrl = serverUrl + "taches/"
const tacheUrl = serverUrl + "tache/{id}/"
const entreesUrl = serverUrl + "user/{id}/entrees/"
const allEntreesUrl = serverUrl + "entrees/"
const entreeUrl = serverUrl + "entree/{id}/"
const periodesSalairesUrl = serverUrl + "salaires/"
const feuilleTemplateUrl = serverUrl + "feuille/{id}/template/"
const feuilleTemplateLoadUrl = serverUrl + "feuille/template/charger/"
const feuilleTemplateDeleteUrl = serverUrl + "feuille/template/supprimer/"
const feuillesPeriodeCouranteUrl = serverUrl + "feuilles/courante/"
const feuillesUrl = serverUrl + "user/{id}/feuilles/"
const userFeuillePeriodeUrl = serverUrl + "user/{id_user}/feuilles/periode/{id_periode}/"
const feuilleUrl = serverUrl + "feuille/{id}/"
const feuilleCouranteUrl = serverUrl + "user/{id}/feuilles/courante/"
const feuilleEntreesUrl = serverUrl + "feuille/{id}/entrees/"
const soumettreFeuilleUrl = serverUrl + "feuille/{id}/soumettre/"
const feuilleExcelUrl = serverUrl + "feuille/{id}/excel/"
const approuverFeuilleUrl = serverUrl + "feuille/{id}/approuver/"
const rejeterFeuilleUrl = serverUrl + "feuille/{id}/rejeter/"
const periodeUrl = serverUrl + "periode/{id}/"
const periodeSalairesUrl = serverUrl + "periode/{id}/salaires/"
const periodeFeuillesUrl = serverUrl + "periode/{id}/feuilles/"
const periodesUrl = serverUrl + "periodes/"
const periodeCouranteUrl = serverUrl + "periodes/courante/"
const userApprobationsUrl = serverUrl + "user/{id}/approbations/"
const approbationsUrl = serverUrl + "approbations/"
const approbationUrl = serverUrl + "approbation/{id}/"
const resetPasswordDemandUrl = serverUrl + "user/reset_password/demand/"
const resetPasswordActionUrl = serverUrl + "user/reset_password/action/"
const resetPasswordCheckLinkUrl = serverUrl + "user/reset_password/check_link/"
const verifyEmailUrl = serverUrl + "verify/email/"
const verifyTelephoneUrl = serverUrl + "verify/telephone/"
const verifyEmailResendCodeUrl = serverUrl + "verify/email/resend/"
const verifyTelephoneResendCodeUrl = serverUrl + "verify/telephone/resend/"
const twoFactorStepOneloginUrl = serverUrl + "login/twofactor/stepone/"
const twoFactorStepTwologinUrl = serverUrl + "login/twofactor/steptwo/"
const twoFactorResendCodeUrl = serverUrl + "login/twofactor/resend/"

export {
    serverUrl,
    loginUrl,
    logoutUrl,
    userInfosUrl,
    affectationsUrl,
    affectationUrl,
    userUpdatePasswordUrl,
    userUrl,
    activateUserUrl,
    deactivateUserUrl,
    userAffectedProjetsUrl,
    usersUrl,
    userFeuillePeriodeUrl,
    registerUrl,
    registerMainAdminUrl,
    registerSecondaryAdminUrl,
    tachesUrl,
    tacheUrl,
    entreesUrl,
    allEntreesUrl,
    entreeUrl,
    projetsUrl,
    projetsRetDUrl,
    prioritesProjetsRetDUrl,
    prioriteProjetsRetDUrl,
    projetUrl,
    periodesSalairesUrl,
    feuilleTemplateDeleteUrl,
    feuilleTemplateLoadUrl,
    feuilleTemplateUrl,
    feuillesPeriodeCouranteUrl,
    feuillesUrl,
    feuilleUrl,
    feuilleCouranteUrl,
    feuilleExcelUrl,
    feuilleEntreesUrl,
    soumettreFeuilleUrl,
    approuverFeuilleUrl,
    rejeterFeuilleUrl,
    periodeUrl,
    periodesUrl,
    periodeCouranteUrl,
    periodeSalairesUrl,
    periodeFeuillesUrl,
    approbationsUrl,
    approbationUrl,
    userApprobationsUrl,
    resetPasswordCheckLinkUrl,
    resetPasswordActionUrl,
    resetPasswordDemandUrl,
    verifyEmailUrl, verifyTelephoneUrl,
    verifyEmailResendCodeUrl,
    verifyTelephoneResendCodeUrl,
    twoFactorStepOneloginUrl,
    twoFactorStepTwologinUrl,
    twoFactorResendCodeUrl
}