<template>
  <div>
    <div v-show="!fetching">
      <v-row>
        <v-col>
          <v-switch v-model="tableSwitch" label="Afficher la table des entrées"></v-switch>
        </v-col>
        <v-col>
          <v-switch v-model="perDayTableSwitch" label="Récaptitulatif par journée"></v-switch>
        </v-col>
        <v-col>
          <v-switch v-model="graphicsSwitch" label="Afficher graphiques"></v-switch>
        </v-col>
      </v-row>
      <br />
      <br />
      <EntreesFilterComponent :entrees="entrees" :customPeriode="periode" v-on:entreesFiltered="setFilteredEntrees"
        v-on:periodeChanged="periodeChanged"></EntreesFilterComponent>
      <br />
      <hr />
      <br />
      <br />
      <br />
      <v-data-table v-show="perDayTableSwitch" sort-by="date" :sort-desc="true" :items="entreesParJour"
        :headers="entreesParJourHeaders" no-data-text="Aucune donnée disponible"
        no-results-text="Aucun résultat correspondant" :footer-props="{
          'items-per-page-text': 'Lignes par page:',
          'page-text': '{0}-{1} sur {2}',
          'items-per-page-all-text': 'Toutes',
        }">
      </v-data-table>
      <br />
      <v-data-table v-if="tableSwitch" locale="fr" :items="filteredEntrees" :headers="headers" sort-by="created"
        :sort-desc="true" no-data-text="Aucune donnée disponible" no-results-text="Aucun résultat correspondant"
        :footer-props="{
          'items-per-page-text': 'Lignes par page:',
          'page-text': '{0}-{1} sur {2}',
          'items-per-page-all-text': 'Toutes',
        }">
        <template #item.created="{ item }">
          {{ formatDateTime(item.created) }}
        </template>
        <template #item.actions="{ item }">
          <td>
            <TimesheetEditComponent v-bind:projets="projets" :taches="taches" v-bind:entree="item">
            </TimesheetEditComponent>
            <v-btn x-small color="red" icon @click="deleteAction(item)"><v-icon>mdi-delete</v-icon></v-btn>
          </td>
        </template>
        <template v-slot:body.append>
          <tr>
            <td>Total</td>
            <td></td>
            <td></td>
            <td>{{ totalHeuresEntrees }}</td>
            <td></td>
          </tr>
        </template>
      </v-data-table>
      <VizualisationsComponent v-show="graphicsSwitch" :entrees="filteredEntrees" :projets="projets" :taches="taches">
      </VizualisationsComponent>

      <ConfirmationPromptComponent ref="confirmationPrompt" titre="Confirmer suppression" v-bind:text="promptText"
        v-bind:callback="promptCallback"></ConfirmationPromptComponent>
    </div>
    <br />
    <br />
    <div v-show="fetching">
      <v-progress-circular indeterminate color="primary" size="100"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TimesheetEditComponent from "./TimesheetEditComponent.vue";
import ConfirmationPromptComponent from "./ConfirmationPromptComponent.vue";
import EntreesFilterComponent from "./EntreesFilterComponent.vue";
import VizualisationsComponent from "./VizualisationsComponent.vue";
export default {
  name: "EntreesTableComponent",
  components: {
    TimesheetEditComponent,
    ConfirmationPromptComponent,
    EntreesFilterComponent,
    VizualisationsComponent,
  },
  data: () => ({
    filteredEntrees: null,
    tableSwitch: true,
    graphicsSwitch: true,
    perDayTableSwitch: true,
    promptText: "",
    promptCallback: null,
    fetching: false,
    entreesParJourHeaders: [
      {
        text: "Utilisateur",
        value: "nom_utilisateur",
      },
      {
        text: "Date",
        value: "date",
      },
      {
        text: "Nombre d'heures",
        value: "nombre_heures",
      },
    ],
  }),
  computed: {
    entreesParJour() {
      var result = [];
      this.filteredEntrees.map((entree) => {
        var date = entree.date;
        var id_utilisateur = entree.utilisateur;
        var nom_utilisateur = entree.nom_utilisateur;
        let nb_heures = parseFloat(entree.nombre_heures);
        let index = result.findIndex(
          (r) => r.date == date && r.utilisateur == id_utilisateur
        );
        if (index != -1) {
          result[index].nombre_heures += nb_heures;
        } else {
          result.push({
            date: date,
            utilisateur: id_utilisateur,
            nombre_heures: nb_heures,
            nom_utilisateur: nom_utilisateur,
          });
        }
      });
      return result.map((r) => {
        return {
          date: r.date,
          utilisateur: r.utilisateur,
          nombre_heures: r.nombre_heures.toFixed(2),
          nom_utilisateur: r.nom_utilisateur,
        };
      });
    },

    totalHeuresEntrees() {
      var total = this.filteredEntrees.reduce((accumulator, entree) => {
        accumulator += parseFloat(entree.nombre_heures);
        return accumulator;
      }, 0);
      return total.toFixed(2);
    },
  },
  props: ["entrees", "projets", "taches", "headers", "periode"],
  methods: {
    ...mapActions([
      "deleteEntree",
      "setEntrees",
      "fetchUserData",
      "fetchProjets",
      "fetchAllEntrees",
    ]),
    setDateDebut(date) {
      this.dateDebutFilter = date;
      let interval = {
        date_debut: this.dateDebutFilter,
        date_fin: this.dateFinFilter,
      };

      this.emmitDateIntervalChanged(interval);
    },
    setDateFin(date) {
      this.dateFinFilter = date;
      let interval = {
        date_debut: this.dateDebutFilter,
        date_fin: this.dateFinFilter,
      };

      this.emmitDateIntervalChanged(interval);
    },
    setFilteredEntrees(newValue) {
      this.filteredEntrees = newValue;
    },

    periodeChanged(range) {
      this.emmitDateIntervalChanged(range)
    },

    emmitDateIntervalChanged(interval) {
      this.fetching = true;

      let callback = () => {
        this.fetching = false;
      }
      let data = { callback: callback, interval: interval }
      this.$emit("intervalChanged", data);
    },

    deleteAction(entree) {
      this.promptText = "Supprimer l'entrée?";
      this.promptCallback = () => {
        this.deleteEntree(entree);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
    formatDateTime(date) {
      if (date != undefined) {
        var dateObject = this.$datetime.fromISO(date);
        return dateObject.toFormat("yyyy-MM-dd HH:mm");
      } else return "";
    },
  },
  created() {
    this.filteredEntrees = this.entrees
  }
};
</script>