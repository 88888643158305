<template>
    <ProjetsRetDComponent class="col-md-10" style="margin:0 auto;"></ProjetsRetDComponent>
</template>

<script>
import ProjetsRetDComponent from "@/components/ProjetsRetDComponent.vue"
export default {
    name:"RETD",
    components:{ProjetsRetDComponent}
}
</script>