<template>
  <div style="margin: 10px auto">
    <v-card elevation="2" shaped>
      <v-card-title>{{ feuille.nom_utilisateur }}</v-card-title>
      <v-card-subtitle style="text-align: initial" v-if="showName != false"
        >Période {{ feuille.nom_periode }}</v-card-subtitle
      >
      <v-card-text style="text-align: initial">
        <p>Statut: <span :class="statutClass(feuille.statut)">{{ feuille.statut }}</span></p>
        <p>Date de création: {{ formatDateTime(feuille.created) }}</p>
        <p v-if="isSoumisOrEnAttente">
          Date de soumission: {{ formatDateTime(feuille.date_soumission) }}
        </p>
        <p v-if="canShowApproveDate">
          Date d'approbation: {{ formatDateTime(feuille.date_approbation) }}
        </p>
        <v-list>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-title
                  ><p>
                    Total heures: {{ formatFloat(feuille.total_heures) }}
                  </p></v-list-item-title
                >
              </v-list-item>
            </template>
            <v-list-item>
              <v-list-item-content>
                <p>
                  Heures régulières:
                  {{ formatFloat(feuille.heures_regulieres) }}
                </p>
                <p>
                  Heures vendredi PM:
                  {{ formatFloat(feuille.heures_vendredi_pm) }}
                </p>
                <p>Heures férie: {{ formatFloat(feuille.heures_ferie) }}</p>
                <p>
                  Heures vacances: {{ formatFloat(feuille.heures_vacances) }}
                </p>
                <p>
                  Heures speciaux: {{ formatFloat(feuille.heures_speciaux) }}
                </p>
                <p>
                  Heures congé mobile/maladie {{ formatFloat(feuille.heures_conge_mobile_maladie) }}
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card-text>
      <v-card-actions
        ><v-btn color="blue" text @click="accederBtnClick">Accéder</v-btn
        ><v-btn
          v-if="canSoumettre"
          color="black"
          text
          @click="soumettreBtnClick"
          >Soumettre</v-btn
        ><v-btn
          color="green"
          text
          @click="approuverBtnClick"
          v-if="canApproveOrReject"
          >Approuver</v-btn
        ><v-btn
          color="red"
          text
          @click="declinerBtnClick"
          v-if="canApproveOrReject"
          >Rejeter</v-btn
        ></v-card-actions
      >
    </v-card>
    <ConfirmationPromptComponent
      ref="confirmationPrompt"
      :text="promptText"
      :titre="promptTitle"
      :callback="promptCallback"
    ></ConfirmationPromptComponent>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmationPromptComponent from "./Dialog/ConfirmationPromptComponent.vue";
export default {
  name: "TimesheetItemComponent",
  props: ["feuille", "showName", "userApprobations","origin"],
  components: { ConfirmationPromptComponent },
  data: () => ({
    promptTitle: "",
    promptText: "",
    promptCallback: null,
  }),
  computed: {
    isUserFeuille() {
      return this.feuille.utilisateur == this.getUser().id;
    },
    canSoumettre() {
      if (this.isUserFeuille) {
        return (
          this.isEnAttente()(this.feuille.statut) ||
          this.isBrouillon()(this.feuille.statut)
        );
      } else {
        return false;
      }
    },
    canEdit() {
      if (this.isMainOrSecondaryAdmin()) return true;
      else {
        if (this.isUserFeuille) return true;
        else if (this.isBrouillonOrEnAttente) return true;
        else return false;
      }
    },
    canShowApproveDate() {
      return this.isApprouve()(this.feuille.statut);
    },
    showApproveOrReject() {
      if (this.canApproveOrReject) {
        return this.isSoumis()(this.feuille.statut);
      } else return false;
    },
    canApproveOrReject() {
      if (this.userApprobations != undefined) {
        let userApprobationsIds = this.userApprobations.map(
          (a) => a.utilisateur
        );
        return userApprobationsIds.includes(this.feuille.utilisateur);
      } else return false;
    },
    isBrouillonOrAttente() {
      let statut = this.feuille.statut;
      return this.isBrouillon()(statut) | this.isEnAttente()(statut);
    },
    isSoumisOrEnAttente() {
      let statut = this.feuille.statut;
      return this.isEnAttente()(statut) | this.isSoumis()(statut);
    },
  },
  methods: {
    ...mapActions([
      "approuverFeuilleDeTemps",
      "rejeterFeuilleDeTemps",
      "soumettreFeuilleDeTemps",
    ]),
    ...mapGetters([
      "getUser",
      "isEnAttente",
      "isBrouillon",
      "isApprouve",
      "isSoumis",
      "getStatutClass",
    ]),
    accederBtnClick() {
      this.$router.push({
        name: "Feuille item",
        params: { feuille_id: this.feuille.id },
      });
    },
    soumettreBtnClick() {
      this.promptTitle = "Soumettre feuille de temps";
      this.promptText =
        "Êtes vous sûr de vouloir soumettre cette feuille de temps? Elle ne pourra plus être modifiée par la suite";
      this.promptCallback = () => {
        var origin=this.origin
        let data={feuille_id:this.feuille.id,origin:origin}
        this.soumettreFeuilleDeTemps(data);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
    approuverBtnClick() {
      this.promptTitle = "Approuver feuille de temps";
      this.promptText =
        "Êtes vous sûr de vouloir approuver cette feuille de temps? Elle ne pourra plus être modifiée par la suite";
      this.promptCallback = () => {
        var origin=this.origin
        let data={feuille_id:this.feuille.id,origin:origin}
        this.approuverFeuilleDeTemps(data);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
    declinerBtnClick() {
      this.promptTitle = "Rejeter feuille de temps";
      this.promptText =
        "Êtes vous sûr de vouloir rejeter cette feuille de temps? Elle pourra modifiée et soumise à nouveau";
      this.promptCallback = () => {
        var origin=this.origin
        let data={feuille_id:this.feuille.id,origin:origin}
        this.rejeterFeuilleDeTemps(data);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
    formatDateTime(date) {
      if (date != undefined) {
        var dateObject = this.$datetime.fromISO(date);
        return dateObject.toFormat("yyyy-MM-dd HH:mm");
      } else return "";
    },
    formatFloat(value) {
      return value.toFixed(2);
    },
    statutClass(statut)
    {
      return this.getStatutClass()(statut)
    }
  },
};
</script>

<style scoped>
.v-list-item {
  padding: 0px;
}
.v-list-item__title p {
  color: rgb(0, 0, 0, 0.6) !important;
}
.v-list-item__content
{
  padding-left: 35px;
}
</style>