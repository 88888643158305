<template>
    <DashboardComponent></DashboardComponent>
</template>

<script>
import DashboardComponent from '../components/DashboardComponent.vue'
export default {
    name:"Dashboard",
    components:{DashboardComponent}
}
</script>