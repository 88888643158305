<template>
  <div class="d-flex flex-column align-center">
    <div class="col-md-12">
      <v-row v-if="isUserFeuille">
        <v-col v-if="!feuilleHaveTemplate"><v-btn small color="primary" @click="saveTemplate">Sauvegarder
            template</v-btn></v-col>
        <v-col v-if="canLoadTemplate"><v-btn small @click="loadTemplate">Charger
            template</v-btn></v-col>
        <v-col v-if="feuilleHaveTemplate" @click="deleteTemplateAction"><v-btn small color="red">Supprimer
            template</v-btn></v-col>
      </v-row>

      <v-row v-if="isMobile">
        <v-col>
          <v-btn @click="selectWeekOne" :class="isWeekOneSelected ? 'primary' : ''">Semaine 1</v-btn>
        </v-col>
        <v-col>
          <v-btn @click="selectWeekTwo" :class="isWeekTwoSelected ? 'primary' : ''">Semaine 2</v-btn>
        </v-col>
      </v-row>
      <br />
      <v-row v-show="entreesToSave.length != 0"><v-col><v-btn @click="saveEntrees">Enregistrer</v-btn></v-col>
        <v-col><v-btn @click="resetTextFields">Réinitialiser</v-btn></v-col></v-row>
      <br />

      <v-simple-table style="width:100%">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Projets</th>
              <th>Taches</th>
              <th :class="getCellClass(header.dateObject)" v-for="(header, index) in headers" :key="index">
                {{ header.dateString }}
              </th>
            </tr>
          </thead>

          <tbody>

            <tr v-for="(selected_projet, index) in selectedProjets" :key="index">
              <td>
                <div>
                  <div class="selected_projet nom_projet">{{ selected_projet.projet.nom_projet }}</div>
                  <div class="selected_projet controls"> <v-btn color="red" icon x-small
                      @click="removeSelectedProjet(selected_projet)"><v-icon>mdi-delete</v-icon></v-btn>

                    <TimesheetProjetSelectComponent :projets="projetsFilteredForRandD" :taches="taches"
                      :previously_selected_projet="selected_projet.projet"
                      :previously_selected_taches="selected_projet.taches" v-on:onSave="projetSelected">
                    </TimesheetProjetSelectComponent>
                  </div>
                </div>
              </td>

              <td class>
                <div class="taches">
                  <div class="selected_projet nom_tache" v-for="tache in selected_projet.taches" :key="tache.id">
                    <v-icon> mdi-circle-small</v-icon>{{
                      tache.nom_tache
                    }}
                    <br />
                  </div>
                </div>
              </td>

              <td :class="getCellClass(header.dateObject)" v-for="(header, index) in headers" :key="index">
                <div class="timesheet_inputs">
                  <v-text-field v-for="tache in selected_projet.taches" :key="tache.id" label="" ref="txt" type="number"
                    min="1" @input="EntreeInputChanged($event, selected_projet.projet, tache, header.dateKey)"
                    :value="getEntreeNbHeures(selected_projet.projet.id, tache.id, header.dateKey)"></v-text-field>
                </div>
              </td>
            </tr>

            <tr>
              <td colspan="2">
                <TimesheetProjetSelectComponent :projets="projetsFilteredForRandD" :taches="taches"
                  v-on:onSave="projetSelected">
                </TimesheetProjetSelectComponent>
              </td>
              <td :class="getCellClass(header.dateObject)" v-for="(header, index) in headers" :key="index">

              </td>
            </tr>

            <tr>
              <td style="font-weight:bold">Total heures</td>
              <td></td>
              <td style="font-weight:bold" :class="getCellClass(header.dateObject)" v-for="(header, index) in headers"
                :key="index">
                {{ formatHeureText(totalHeures[header.dateKey]) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <br />
    </div>
    <br />

    <div>
      <v-snackbar v-model="snackbar">
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Fermer
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <ConfirmationPromptComponent ref="confirmationPrompt" titre="Confirmer suppression" v-bind:text="promptText"
      v-bind:callback="promptCallback"></ConfirmationPromptComponent>
  </div>
</template>

<script>
import ConfirmationPromptComponent from "@/components/Dialog/ConfirmationPromptComponent.vue";
import TimesheetProjetSelectComponent from "./TimesheetProjetSelectComponent.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "TimesheetFormCalendarComponent",
  props: ["periode", "feuille", "entrees", "projets", "taches"],
  components: { ConfirmationPromptComponent, TimesheetProjetSelectComponent },
  data: () => ({
    selectedProjets: [],
    entreesToSave: [],
    date_debut: null,
    daysInTable: 14,
    isWeekOneSelected: true,
    isWeekTwoSelected: false,
    dialog: false,
    snackbar: false,
    snackbar_text: "",
    promptText: "",
    promptCallback: null,
  }),
  computed: {
    utilisateur() {
      return this.getUser();
    },

    canLoadTemplate() {
      var res = this.isFeuilleCourante && this.canUseForm && this.feuilleHaveTemplate
      return res
    },

    isUserFeuille() {
      return this.feuille.utilisateur == this.getUser().id;
    },

    isFeuilleCourante() {
      return this.feuille.feuille_courante
    },

    feuilleHaveTemplate() {
      return this.feuille.has_template
    },

    canUseForm() {
      if (this.isBrouillonOrEnAttente()) {
        if (this.isAdmin()) {
          return true
        }
        else
          return this.isUserFeuille
      }
      else
        return false
    }
    ,

    headers() {
      var dateTime = this.$datetime;
      // var dateObject = dateTime.fromISO(this.date_debut). .startOf("week");
      var dateObject = dateTime.fromISO(this.periode.date_debut);
      if (dateObject.weekday != 7) {
        dateObject = dateObject.minus({ weeks: 1 }).set({ weekday: 7 });
      }

      if (this.isWeekTwoSelected) {
        dateObject = dateObject.plus({ weeks: 1 });
      }

      var dateString = dateObject.toFormat("DDDD");
      var dateKey = dateObject.toFormat("yyyy-MM-dd");

      let result = [];
      var i = 1;

      do {

        dateString = this.shortenDateString(dateString)

        var header = { dateString, dateObject, dateKey };
        result.push(header);
        dateObject = dateObject.plus({ days: 1 });
        dateString = dateObject.toFormat("DDDD");
        dateKey = dateObject.toFormat("yyyy-MM-dd");
        i++;
      } while (i <= this.daysInTable);

      return result
    },

    totalHeures() {
      return this.headers.reduce((accumulator, h) => {
        let date = h.dateKey
        let nb_heures = 0

        // this.entreesToSave.map((e) => {
        //   if (e.date == date) {
        //     nb_heures += parseFloat(e.nombre_heures)
        //   }
        // })
        this.entrees.map((e) => {
          if (e.date == date) {
            nb_heures += parseFloat(e.nombre_heures)
          }
        })

        accumulator[date] = nb_heures

        return accumulator
      }, {})
    },

    projetsFilteredForRandD() {
      var isUserAffectedtoRetD = this.isAffectedToRetD();
      return this.projets.filter((p) => {
        if (p.nom_projet == "R&D") {
          if (isUserAffectedtoRetD) return true;
          else return false;
        } else return p.rEtD == false;
      });
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    ...mapActions(["fetchProjets", "fetchTaches", "addEntrees", "saveFeuilleAsTemplate", "loadFeuilleTemplate", "updateEntree", "deleteFeuilleTemplate", "deleteEntree"]),
    ...mapGetters(["getUser", "isAffectedToRetD", "getProjets", "isAdmin",
      "isEnAttente",
      "isBrouillon",
      "isApprouve",
      "isSoumis",
      "getStatutClass",]),

    getEntree(projet_id, tache_id, date) {
      let index = this.entrees.findIndex((_) => {
        return _.date == date && _.tache == tache_id && _.projet == projet_id
      })

      if (index == -1)
        return undefined

      return this.entrees[index]
    },

    getEntreeNbHeures(projet_id, tache_id, date) {
      let entree = this.getEntree(projet_id, tache_id, date)

      if (entree == undefined) {
        return ""
      }

      return entree.nombre_heures
    },

    EntreeInputChanged(newValue, projet, tache, date) {
      if (newValue == "" || newValue == 0 || newValue == null) {
        this.removeEntree(projet, tache, date)
      }
      else {
        this.addOrUpdateEntree(newValue, projet, tache, date)
      }
    },

    addOrUpdateEntree(nb_heures, projet, tache, date) {
      let index = this.entreesToSave.findIndex((e) => e.date == date && e.projet == projet.id && e.tache == tache.id)
      if (index == -1) {
        this.addEntreeToSave(nb_heures, projet, tache, date)
      }
      else {
        this.updateEntreeToSave(nb_heures, index)
      }
    },

    addEntreeToSave(nb_heures, projet, tache, date) {

      let nombre_heures = nb_heures

      let utilisateur = this.feuille.utilisateur;
      let feuille_de_temps = this.feuille.id;

      let entree = { projet: projet.id, tache: tache.id, date, nombre_heures, utilisateur, feuille_de_temps }
      this.entreesToSave.push(entree)
    },

    updateEntreeToSave(nb_heures, index) {
      this.entreesToSave[index].nombre_heures = nb_heures
    },

    removeEntree(projet, tache, date) {
      let index = this.entreesToSave.findIndex((e) => e.date == date && e.projet == projet.id && e.tache == tache.id)
      if (index != -1) {
        this.entreesToSave.splice(index, 1)
      }
    },


    setEntreesAndDefaultProjets() {
      const defaultProjets = ["CONGÉS ET VACANCES", "Projet Global"]

      let entrees_projets_id = []
      let entrees_taches_id = []
      let entrees_projets = []
      let entrees_taches = []

      let doEntreeContainsProjetGlobal=false
      let doEntreeContainsProjetVacances=false

      this.entrees.map((e) => {
        const id_projet = e.projet
        const id_tache = e.tache

        if(e.nom_projet==defaultProjets[0])
        {
          doEntreeContainsProjetVacances=true
        }
        
        if(e.nom_projet==defaultProjets[1])
        {
          doEntreeContainsProjetGlobal=true
        }


        if (!entrees_projets_id.includes(id_projet)) {
          let projet = this.projets.find((p) => p.id == id_projet)
          entrees_projets_id.push(id_projet)
          entrees_projets.push(projet)
        }

        if (!entrees_taches_id.includes(id_tache)) {
          let tache = this.taches.find((t) => t.id == id_tache)
          entrees_taches_id.push(id_tache)
          entrees_taches.push(tache)
        }
      })

      let result=[]
      
      entrees_projets.map((p) => {
        let projetTaches = entrees_taches.filter((t) => t.projet == p.id)
        let data= { projet: p, taches: projetTaches }
        result.push(data)
      })

      if(!doEntreeContainsProjetGlobal)
      {
        let projet=this.projets.find((p) => p.nom_projet==defaultProjets[1])
        let taches= this.taches.filter((t) => t.projet == projet.id)
        let data={ projet: projet, taches: taches }
        result.push(data)
      }

      if(!doEntreeContainsProjetVacances)
      {
        let projet=this.projets.find((p) => p.nom_projet==defaultProjets[0])
        let taches= this.taches.filter((t) => t.projet == projet.id)
        let data={ projet: projet, taches: taches }
        result.push(data)
      }

      this.selectedProjets=result

    },


    projetSelected(selected_projet) {
      let index = this.selectedProjets.findIndex((_) => _.projet?.id == selected_projet.projet.id)
      let taches = selected_projet.taches
      let isProjetAlreadySelected = index != -1 ? true : false
      let isSelectedTachesEmpty = taches.length == 0 ? true : false

      if (isProjetAlreadySelected) {
        // //Remove projet from selection bc no tache selected
        // if (isSelectedTachesEmpty) {
        //   this.selectedProjets.splice(index, 1)
        // }


        //Add non previously selected taches only
        if (!isSelectedTachesEmpty) {
          let previously_selected_taches = this.selectedProjets[index].taches

          let non_previously_selected_taches = taches.filter((t) => {
            index = previously_selected_taches.findIndex((_) => _.id == t.id)
            let is_tache_previously_selected = index != -1 ? true : false
            return !is_tache_previously_selected
          })

          for (var i = 0; i < non_previously_selected_taches.length; i++) {
            previously_selected_taches.push(non_previously_selected_taches[i])
          }
        }

      }


      if (!isProjetAlreadySelected && !isSelectedTachesEmpty)
        this.selectedProjets.push(selected_projet)
    },

    isProjetSelected(id_projet) {
      const index = this.selectedProjets.findIndex((_) => _.projet.id == id_projet)
      return index != -1 ? true : false
    },

    removeSelectedProjet(selected_projet) {
      let index = this.selectedProjets.findIndex((_) => _.projet?.id == selected_projet.projet.id)
      if (index != -1)
        this.selectedProjets.splice(index, 1)
    },

    async saveEntrees() {
      var toUpdate = []
      var toAdd = []
      this.entreesToSave.map((e) => {
        const projet = e.projet
        const tache = e.tache
        const date = e.date

        let entree = this.getEntree(projet, tache, date)

        if (entree != undefined) {
          e.id = entree.id
          toUpdate.push(e)
        }
        else {
          toAdd.push(e)
        }

      })

      if (toAdd.length != 0) {
        let result = await this.addEntrees(toAdd)

        if (!result) {
          return
        }
      }

      if (toUpdate.length != 0) {
        let results = []
        for (var i = 0; i < toUpdate.length; i++) {
          let result = await this.updateEntree(toUpdate[i])
          results.push(result)
        }

        if (!(results.some((r) => r))) {
          return
        }
      }

      this.entreesToSave = []
      this.snackbar_text = "Vos entrées ont bien été enregistrées"
      this.snackbar = true
    },

    loadTemplate() {
      this.promptText = "Charger template de feuille de temps? Les entrees précédentes seront effacées!";
      this.promptCallback = async () => {
        let result = await this.loadFeuilleTemplate()
        if (result) {
          this.snackbar_text = "Template chargé"
          this.snackbar = true
          this.setEntreesAndDefaultProjets()
        }
      };
      this.$refs.confirmationPrompt.dialog = true;
    },

    async saveTemplate() {
      if (this.entrees.length != 0) {
        let result = await this.saveFeuilleAsTemplate(this.feuille.id)
        if (result) {
          this.feuille.has_template = true
          this.snackbar_text = "Template sauvegardé"
          this.snackbar = true
        }
      }
      else {
        this.snackbar_text = "Votre feuille doit comporter des entrées pour pouvoir effectuer cette action"
        this.snackbar = true
      }
    },


    deleteTemplateAction() {
      this.promptText = "Supprimer template de feuille de temps?";
      this.promptCallback = async () => {
        let result = await this.deleteFeuilleTemplate();
        if (result) {
          this.feuille.has_template = false
          this.snackbar_text = "Template supprimé"
          this.snackbar = true
        }
      };
      this.$refs.confirmationPrompt.dialog = true;
    },

    formatHeureText(nb_heures) {
      if (nb_heures == 0)
        return nb_heures
      if (nb_heures < 2)
        return nb_heures + " heure"

      else
        return nb_heures + " heures"

    },

    shortenDateString(dateString) {
      const daysCharNb = 3
      var monthsCharNb = 3

      let parts = dateString.split(" ")
      let day = parts[0]
      let month = parts[2]

      monthsCharNb = month == "juillet" ? 4 : 3;

      day = day.substring(0, daysCharNb)
      month = month.substring(0, monthsCharNb)

      let date = day + " " + parts[1] + " " + month + " " + parts[3]

      return date
    },

    resetTextFields() {
      let textFields = this.$refs["txt"]
      for (var i = 0; i < textFields.length; i++) {
        try {
          var input = textFields[i].reset()
          // input.value = ""
          // input.valueAsNumber = NaN
          console.log(input)
        } catch (e) {
          console.log(e);
        }
      }
    },

    selectWeekOne() {
      this.isWeekOneSelected = true
      this.isWeekTwoSelected = false
    },

    selectWeekTwo() {
      this.isWeekOneSelected = false
      this.isWeekTwoSelected = true
    },

    quitter() {
      this.$router.push({ path: "/feuille" });
    },

    getCellClass(dateObject) {
      if (dateObject.weekday == 7 || dateObject.weekday == 6) return "bggrey";
      else return "";
    },
    isBrouillonOrEnAttente() {
      let statut = this.feuille.statut;
      return this.isEnAttente()(statut) | this.isBrouillon()(statut);
    },
    isSoumisOrEnAttente() {
      let statut = this.feuille.statut;
      return this.isEnAttente()(statut) | this.isSoumis()(statut);
    },
  },
  created() {
    this.daysInTable = this.isMobile ? 7 : 14
    this.setEntreesAndDefaultProjets()
  }
};
</script>

<style scoped>
.bggrey {
  background-color: lightgray;
}

.v-card {
  margin-top: 5px;
  margin-bottom: 5px;
}

.v-card__title {
  justify-content: center;
  font-size: 0.7rem;
  word-break: normal;
  padding: 5px;
  line-height: normal;
}

.v-card__text {
  font-size: 0.7rem;
  padding: 0px;
}

.v-card__actions {
  justify-content: center;
}


.taches {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.taches .nom_tache {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timesheet_inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.selected_projet {
  display: flex;
  justify-content: center;
}

.selected_projet.nom_projet {
  font-weight: bold;
  text-decoration: underline;
}
</style>