<template>
    <LoginTwoFactorComponent>
    </LoginTwoFactorComponent>
</template>

<script>
import LoginTwoFactorComponent from "@/components/LoginTwoFactorComponent.vue"
export default {
    name:"LoginTwoFactor",
    components:{LoginTwoFactorComponent}
}
</script>