<template>
  <div class="col-md-6" style="margin: 0 auto">
    <h1>Réinitialiser mot de passe</h1>
    <div v-if="dataFetched">
      <div v-if="!error">
        <div v-if="!success">
          <p>Veuillez entrer votre nouveau mot de passe</p>
          <v-form @submit.prevent="submitForm">
            <div>
              <div>
                <div v-if="$v.motDePasse.$dirty">
                  <v-alert class="error" v-if="!$v.motDePasse.required">
                    Ce champ est requis
                  </v-alert>
                  <v-alert class="error" v-if="!$v.motDePasse.minLength">
                    Le mot de passe doit contenir au moins 4 caractères
                  </v-alert>
                </div>
                <v-text-field v-model="$v.motDePasse.$model" :type="passwordVisible ? 'text' : 'password'"
                  :append-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="passwordVisible = !passwordVisible" label="Mot de passe"></v-text-field>
              </div>
              <div>
                <div v-if="$v.cMotDePasse.$dirty">
                  <v-alert class="error" v-if="!$v.cMotDePasse.required">
                    Ce champ est requis
                  </v-alert>
                  <v-alert class="error" v-if="!$v.cMotDePasse.minLength">
                    Le mot de passe doit contenir au moins 4 caractères
                  </v-alert>
                  <v-alert class="error" v-if="!$v.cMotDePasse.sameAsPassword">
                    Les deux mots de passe doivent correspondre
                  </v-alert>
                </div>
                <v-text-field v-model="$v.cMotDePasse.$model" :type="cPasswordVisible ? 'text' : 'password'"
                    :append-icon="cPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="cPasswordVisible = !cPasswordVisible" label="Confirmer mot de passe">
                </v-text-field>
              </div>
            </div>
            <v-btn type="submit">Envoyer</v-btn>
          </v-form>
        </div>
        <div v-if="success">
          <br />
          <br />
          <v-alert class="success">
            Votre mot de passe a bien été réinitialisé.<br />
            <router-link to="/connexion">Connexion</router-link>
          </v-alert>
        </div>
      </div>
      <div v-if="error">
        <br />
        <br />
        <v-alert class="error">
          Le lien de réinitialisation du mot de passe est expiré ou incorrect
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  name: "PasswordResetActionComponent",
  data: () => ({
    motDePasse: "",
    cMotDePasse: "",
    passwordVisible: false,
    cPasswordVisible: false,
    resetDemand: null,
    error: true,
    success: false,
    dataFetched: false,
  }),
  props: ["reinitialisation_link"],
  validations: {
    motDePasse: {
      required,
      minLength: minLength(4),
    },
    cMotDePasse: {
      required,
      minLength: minLength(4),
      sameAsPassword: sameAs("motDePasse"),
    },
  },
  methods: {
    ...mapActions(["resetPasswordAction", "checkResetPasswordLink"]),
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var data = { new_password: this.motDePasse, id: this.resetDemand.id };
        this.resetPasswordAction(data).then((result) => {
          this.success = result;
        });
      }
    },
  },
  created() {
    var data = { link: this.reinitialisation_link };
    this.checkResetPasswordLink(data).then((result) => {
      if (result != null) {
        this.error = false;
        this.resetDemand = result;
      }
      this.dataFetched = true;
    });
  },
};
</script>