<template>
<v-container>
    <FeuilleComponent style="margin:auto"></FeuilleComponent>
</v-container>
</template>

<script>
import FeuilleComponent from '../components/FeuilleComponent.vue'
export default {
    name:"Feuille",
    components:{FeuilleComponent}
}
</script>