<template>
    <AdminDashboardComponent class="col-md-6"></AdminDashboardComponent>
</template>

<script>
import AdminDashboardComponent from "@/components/AdminDashboardComponent.vue"
export default {
    name:"AdminDashboard",
    components:{AdminDashboardComponent}
}
</script>