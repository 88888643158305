<template>
  <v-app>
    <v-app-bar app>
       <router-link to="/feuille" id="logo"><img src="./assets/logo.svg"></router-link>
       <div class="menu">
         <router-link v-show="isLogged()" to="/feuille">Feuilles de temps</router-link>
         <router-link v-show="isAdmin()" to="/admin">Interface Administrateur</router-link>
         <router-link v-show="!isLogged()" to="/connexion">Connexion</router-link>
         <router-link v-show="isLogged()" to="/deconnexion">Deconnexion</router-link>
       </div>
    </v-app-bar>
    <v-main><router-view></router-view></v-main>
    <v-footer>2023 Nidine techno</v-footer>
  </v-app>
</template>

<script>
import {mapGetters} from 'vuex'
export default{
  methods:{
    ...mapGetters(["isLogged","isAdmin"])
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

h1
{
  color:black;
}

#logo
{
  height:100%
}
#logo img
{
  height:100%
}

.v-toolbar__content
{
  justify-content: space-between;
}

.menu
{
  margin-right: 20px;

  a{
    margin: 0 20px;
   color:grey !important;
  }
  a.router-link-exact-active{
    color:black !important;
  }
}

.v-dialog > .v-card > .v-card__title
{
  justify-content:center
}

.text-red
{
  color: red;
}
.text-green
{
  color: green;
}
.text-blue
{
  color:#2196F3;
}


</style>
