<template>
<div><PasswordResetActionComponent :reinitialisation_link="reinitialisation_link"></PasswordResetActionComponent></div>
</template>

<script>
import PasswordResetActionComponent from "@/components/PasswordResetActionComponent.vue"
export default {
    name:"PasswordReset",
    props:["reinitialisation_link"],
    components:{PasswordResetActionComponent}
}
</script>