<template>
  <div v-if="dataFetched">
    <v-alert v-show="error" type="error"
      >Le total des affectations d'un utilisateur ne peut être supérieur ou
      inférieur a 100</v-alert
    >
    <v-simple-table :key="tableKey">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Utilisateurs/Projets</th>
            <th v-for="projet in projets" :key="projet.id">
              {{ projet.nom_projet }}
            </th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="utilisateur in utilisateurs" :key="utilisateur.id">
            <td>{{ utilisateur.prenom }} {{ utilisateur.nom }}</td>
            <td v-for="projet in projets" :key="projet.id">
              <div
                v-if="isAffected(utilisateur.id, projet.id)"
                class="d-flex justify-space-between align-center"
              >
                <v-text-field
                  :value="getNombreHeures(utilisateur.id, projet.id)"
                  :disabled="!edit"
                  max="100"
                  type="number"
                  class="nombreHeuresTxt"
                  :id="'nombreHeuresTxt' + utilisateur.id + projet.id"
                  @input="nombreHeuresInput($event, utilisateur.id, projet.id)"
                ></v-text-field>
                <div v-show="edit">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="disafectUser(utilisateur.id, projet.id)"
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="red"
                        ><v-icon>mdi-close-circle-outline</v-icon></v-btn
                      >
                    </template>
                    <span>Désaffecter l'utilisateur du projet</span>
                  </v-tooltip>
                </div>
              </div>
              <div
                 v-if="!isAffected(utilisateur.id, projet.id) && edit"
                class="d-flex justify-space-between align-center"
              >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="affectUser(utilisateur.id, projet.id)"
                    v-on="on"
                    v-bind="attrs"
                     icon
                    ><v-icon>mdi-account-plus</v-icon></v-btn
                  >
                </template>
                <span>Affecter l'utilisateur au projet</span>
              </v-tooltip>
              </div>
            </td>
            <td>{{ getTotalHeures(utilisateur.id) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <br />
    <div>
      <v-btn v-if="!edit" @click="enableEdit">Modifier</v-btn>
      <v-btn v-if="edit" @click="save">Enregistrer</v-btn>
      <v-btn v-if="edit" @click="disableEdit">Annuler</v-btn>
    </div>
    <ConfirmationPromptComponent
      ref="confirmationPrompt"
      titre="Confirmer affectation"
      :text="promptText"
      :callback="promptCallback"
    ></ConfirmationPromptComponent>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmationPromptComponent from "./ConfirmationPromptComponent.vue";
export default {
  name: "MatriceAffectationsComponent",
  components: { ConfirmationPromptComponent },
  data: () => ({
    dataFetched: false,
    projets: null,
    utilisateurs: null,
    affectations: [],
    affectationsCopy: [],
    disaffectations: [],
    edit: false,
    promptText: "",
    promptCallback: null,
    tableKey: 0,
  }),
  computed: {
    utilisateursAffectes() {
      if (this.dataFetched) {
        return this.affectationsCopy.reduce((accumulateur, affectation) => {
          if (!accumulateur.includes(affectation.utilisateur)) {
            accumulateur.push(affectation.utilisateur);
          }
          return accumulateur;
        }, []);
      }
      return [];
    },
    error() {
      var result = false;
      // if (this.dataFetched) {
      //   this.totalNombreHeuresUtilisateur.map((total) => {
      //     if (total.nombre_heures != 100) {
      //       result = true;
      //     }
      //   });
      // }
      return result;
    },
    totalNombreHeuresUtilisateur() {
      if (this.dataFetched) {
        return this.utilisateursAffectes.map((utilisateur_id) => {
          var totalNombreHeuresUtilisateur = this.affectationsCopy.reduce(
            (accumulateur, affectation) => {
              if (affectation.utilisateur == utilisateur_id) {
                accumulateur += affectation.nombre_heures;
              }
              return accumulateur;
            },
            0
          );
          return {
            utilisateur_id: utilisateur_id,
            nombre_heures: totalNombreHeuresUtilisateur,
          };
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapGetters(["getAffectations"]),
    ...mapActions([
      "fetchProjetsRetD",
      "fetchUtilisateurs",
      "fetchAffectations",
      "addAffectation",
      "updateAffectation",
      "deleteAffectation",
    ]),
    save() {
      if (!this.error) {
        this.saveAffectations();
      }
    },
    saveAffectations() {
      if (this.disaffectations.length != 0) {
        this.disaffectations.map((d) => {
          if (Object.keys(d).includes("id")) {
            this.deleteAffectation(d);
          }
        });
        this.disaffectations = [];
      }

      Promise.all(
        this.affectationsCopy.map((a) => {
          if (Object.keys(a).includes("id")) return this.updateAffectation(a);
          else return this.addAffectation(a);
        })
      ).then(() => {
        this.disableEdit();
      });
    },
    nombreHeuresInput(value, utilisateur_id, projet_id) {
      try {
        let nombre_heures = parseFloat(value);
        var affectation = this.affectationsCopy.find(
          (a) => a.projet == projet_id && a.utilisateur == utilisateur_id
        );
        affectation.nombre_heures = nombre_heures;
      } catch (err) {
        console.log(err);
      }
    },
    enableEdit() {
      this.edit = true;
    },
    disableEdit() {
      this.disaffectations = [];
      this.affectationsCopy = this.affectations.map((a) => {
        return { ...a };
      });
      this.edit = false;
    },
    affectUser(utilisateur_id, projet_id) {
      this.promptText =
        "Affecter l'utilisateur " +
        this.getUsername(utilisateur_id) +
        " au projet " +
        this.getProjectName(projet_id);
      this.promptCallback = () => {
        var affectationIndex = this.disaffectations.findIndex(
          (d) => d.utilisateur == utilisateur_id && d.projet == projet_id
        );
        //affectation existante qui avait ete supprimée
        if (affectationIndex != -1)
          this.affectationsCopy.push(this.disaffectations[affectationIndex]);
        else {
          var affectation = {
            projet: projet_id,
            utilisateur: utilisateur_id,
            nombre_heures: 0,
          };
          this.affectationsCopy.push(affectation);
        }
        this.tableKey += 1;
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
    disafectUser(utilisateur_id, projet_id) {
      this.promptText =
        "Désaffecter l'utilisateur " +
        this.getUsername(utilisateur_id) +
        " du projet " +
        this.getProjectName(projet_id);
      this.promptCallback = () => {
        var affectationIndex = this.affectationsCopy.findIndex(
          (a) => a.utilisateur == utilisateur_id && a.projet == projet_id
        );
        var affectation = this.affectationsCopy[affectationIndex];

        //Affectation non existante dans le backend
        if (Object.keys(affectation).includes("id")) {
          this.disaffectations.push(affectation);
        }
        this.affectationsCopy.splice(affectationIndex, 1);
        this.tableKey += 1;
      };
      this.$refs.confirmationPrompt.dialog = true;
    },

    isAffected(utilisateur_id, projet_id) {
      var index = this.affectationsCopy.findIndex(
        (a) => a.utilisateur == utilisateur_id && a.projet == projet_id
      );
      return index == -1 ? false : true;
    },

    getTotalHeures(utilisateur_id) {
      let userTotalnombre_heuresIndex =
        this.totalNombreHeuresUtilisateur.findIndex(
          (total) => total.utilisateur_id == utilisateur_id
        );
      if (userTotalnombre_heuresIndex != -1) {
        return this.totalNombreHeuresUtilisateur[userTotalnombre_heuresIndex]
          .nombre_heures;
      } else return "";
    },
    userRemainingnombre_heures(utilisateur_id) {
      var percentage = this.affectationsCopy.reduce(
        (accumulator, affectation) => {
          if (affectation.utilisateur == utilisateur_id) {
            accumulator -= parseFloat(affectation.nombre_heures);
          }
          return accumulator;
        },
        100
      );
      return percentage;
    },
    getUsername(utilisateur_id) {
      var index = this.utilisateurs.findIndex((u) => u.id == utilisateur_id);
      var utilisateur = this.utilisateurs[index];
      return utilisateur.prenom + " " + utilisateur.nom;
    },
    getProjectName(projet_id) {
      var index = this.projets.findIndex((p) => p.id == projet_id);
      var projet = this.projets[index];
      return projet.nom_projet;
    },
    getNombreHeures(utilisateur_id, projet_id) {
      var index = this.affectationsCopy.findIndex(
        (a) => a.utilisateur == utilisateur_id && a.projet == projet_id
      );
      return this.affectationsCopy[index].nombre_heures;
    },
  },
  created() {
    this.fetchProjetsRetD().then((p) => {
      this.projets = p;
      this.fetchUtilisateurs().then((u) => {
        this.utilisateurs = u;
        this.fetchAffectations().then((a) => {
          this.affectations = a;
          this.affectationsCopy = a.map((a) => {
            return { ...a };
          });
          this.dataFetched = true;
        });
      });
    });
  },
};
</script>