<template>
    <v-dialog v-model="dialog" scrollable max-width="300px">
      <v-card>
        <v-card-title class="text-center">{{ titre }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{text}}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn @click="confirm">Confirmer</v-btn>
          <v-btn @click="cancel">Annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>


<script>
export default {
  name: "ConfirmationPromptComponent",
  props: ["text","titre","callback"],
  data: () => ({
    dialog: false,
  }),
  methods: {
    confirm() {
      this.callback();
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
     
    },
    emitResult()
    {
        this.$emit("dialogResult",this.result)
    }
  },
};
</script>