<template>
  <div v-if="dataFetched" class="col-md-10" style="margin: 0 auto">
    <div v-if="canAccessFeuille">
      <!--<div>
        <v-btn>Extraire excel</v-btn>
      </div>-->
      <h1>Feuille de temps {{ feuille.nom_periode }}</h1>
      <h2>{{ feuille.nom_utilisateur }}</h2>
      <br />
      <div>
        <p>Date création: {{ formatDateTime(feuille.created) }}</p>
        <p>
          Statut:
          <span :class="statutClass(feuille.statut)">{{ feuille.statut }}</span>
        </p>
        <p v-if="isSoumisOrEnAttente">
          Date de soumission: {{ formatDateTime(feuille.date_soumission) }}
        </p>
        <p v-if="canShowApproveDate">
          Date d'approbation: {{ formatDateTime(feuille.date_approbation) }}
        </p>
        <v-row>
          <v-col v-if="canSoumettre">
            <v-btn @click="soumettreBtnClick">Soumettre</v-btn>
          </v-col>
          <v-col v-if="showApproveOrReject">
            <v-btn @click="approuverBtnClick" color="green">Approuver</v-btn>
          </v-col>
          <v-col v-if="showApproveOrReject">
            <v-btn @click="declinerBtnClick" color="red">Rejeter</v-btn>
          </v-col>
        </v-row>
        <div>
          <br />
          <p>
            Exporter(format Excel):
            <v-btn @click="excelBtnClick" icon color="green" x-large>
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </p>
          <a v-show="false" download="feuille.xlsx" ref="excelDownloadLink"></a>
        </div>
      </div>
      <br />
      <hr />
      <v-switch label="Afficher formulaire" v-model="entreesSwitch"></v-switch>
      <br />
      <div v-show="entreesSwitch">
        <h2>Formulaire</h2>
        <div>
          <TimesheetFormCalendarComponent :entrees="feuilleEntreesFiltered" :periode="periode" :feuille="feuille" :projets="projets" :taches="taches">
          </TimesheetFormCalendarComponent>
        </div>
      </div>
      <div>
        <h2>Entrées</h2>
        <div>
          <TimesheetEntreesCalendarComponent :entrees="feuilleEntreesFiltered" :periode="periode" :feuille="feuille" :projets="projets" :taches="taches">
          </TimesheetEntreesCalendarComponent>
        </div>
      </div>
      <br />
      <hr/>
      <br />
      <v-switch v-model="graphicsSwitch" label="Afficher graphiques"></v-switch>
      <div>
        <!-- <EntreesTableComponent
          :periode="periode"
          :entrees="feuilleEntrees"
          :headers="entreeTableHeaders"
          v-on:periodeChanged="fetchEntreesByRange"
        ></EntreesTableComponent> -->
        <EntreesFilterComponent v-bind:entrees="feuilleEntrees" :customPeriode="periode"
          v-on:entreesFiltered="setFilteredEntrees" v-on:periodeChanged="intervalChanged"></EntreesFilterComponent>
        <br />
        <br />j
        <VizualisationsComponent v-show="graphicsSwitch" :entrees="feuilleEntreesFiltered" :projets="projets"
          :taches="taches">
        </VizualisationsComponent>
        <br />
        <br />
      </div>
      <ConfirmationPromptComponent ref="confirmationPrompt" :titre="promptTitle" :text="promptText"
        :callback="promptCallback"></ConfirmationPromptComponent>
    </div>
    <div v-if="!canAccessFeuille">
      <v-alert class="error">Vous n'avez pas les doits d'accès à cette feuille de temps</v-alert>
    </div>
  </div>
</template>

<script> 
import TimesheetFormCalendarComponent from "./TimesheetFormCalendarComponent.vue";
import TimesheetEntreesCalendarComponent from "./TimesheetEntreesCalendarComponent.vue";
import EntreesFilterComponent from "./EntreesFilterComponent.vue";
import VizualisationsComponent from "./VizualisationsComponent.vue";
import ConfirmationPromptComponent from "./Dialog/ConfirmationPromptComponent.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "TimesheetSingleComponent",
  components: {
    TimesheetFormCalendarComponent,
    TimesheetEntreesCalendarComponent,
    ConfirmationPromptComponent,
    EntreesFilterComponent,
    VizualisationsComponent
  },
  data: () => ({
    projets: null,
    taches: null,
    promptText: "",
    promptTitle: "",
    promptCallback: null,
    feuille: null,
    feuilleEntrees: null,
    feuilleEntreesFiltered: null,
    periode: null,
    userApprobations: null,
    entreesSwitch: true,
    graphicsSwitch: true,
    fetchProgress: [false, false, false],
  }),
  props: ["feuille_id"],
  computed: {
    canAccessFeuille() {
      if (this.isMainOrSecondaryAdmin()) {
        return true;
      } else {
        return this.isUserFeuille;
      }
    },
    canSoumettre() {
      if (this.isUserFeuille) {
        return (
          this.isEnAttente()(this.feuille.statut) ||
          this.isBrouillon()(this.feuille.statut)
        );
      } else {
        return false;
      }
    },
    canEdit() {
      if (this.isMainOrSecondaryAdmin()) return true;
      else {
        if (this.isUserFeuille && this.isBrouillonOrEnAttente) return true;
        else return false;
      }
    },
    showApproveOrReject() {
      if (this.canApproveOrReject) {
        return this.isSoumis()(this.feuille.statut);
      } else return false;
    },
    canApproveOrReject() {
      let userApprobationsIds = this.userApprobations.map((a) => a.utilisateur);
      return userApprobationsIds.includes(this.feuille.utilisateur);
    },
    canUseForm() {
      return this.isUserFeuille && this.isBrouillonOrEnAttente;
    },
    isBrouillonOrEnAttente() {
      let statut = this.feuille.statut;
      return this.isEnAttente()(statut) | this.isBrouillon()(statut);
    },
    isSoumisOrEnAttente() {
      let statut = this.feuille.statut;
      return this.isEnAttente()(statut) | this.isSoumis()(statut);
    },
    canShowApproveDate() {
      return this.isApprouve()(this.feuille.statut);
    },
    isUserFeuille() {
      return this.feuille.utilisateur == this.getUser().id;
    },
    dataFetched() {
      return this.feuille != null && this.feuilleEntrees != null && this.periode != null && this.userApprobations != null && this.projets != null && this.taches != null
    },
    entreeTableHeaders() {
      var headers = [
        {
          text: "Projet",
          value: "nom_projet",
          width: "250px",
        },
        { text: "Tache", value: "nom_tache", width: "200px" },
        { text: "Date", value: "date" },
        { text: "Date de la saisie", value: "created" },
        { text: "Heures", value: "nombre_heures" },
      ];
      if (this.canEdit) {
        headers.push({ text: "Actions", value: "actions", sortable: false });
      }
      return headers;
    },
  },
  methods: {
    ...mapActions([
      "fetchProjets",
      "fetchTaches",
      "fetchFeuilleById",
      "fetchPeriode",
      "fetchFeuilleEntrees",
      "fetchUserApprobations",
      "soumettreFeuilleDeTemps",
      "approuverFeuilleDeTemps",
      "rejeterFeuilleDeTemps",
      "fetchFeuilleExcel",
      "setEntrees"
    ]),
    ...mapGetters([
      "getUser",
      "getProjets",
      "isMainOrSecondaryAdmin",
      "isEnAttente",
      "isBrouillon",
      "isApprouve",
      "isSoumis",
      "getStatutClass",
    ]),
    soumettreBtnClick() {
      this.promptTitle = "Soumettre feuille de temps";
      this.promptText =
        "Êtes vous sûr de vouloir soumettre votre feuille de temps? Vous ne pourrez plus la modifier par la suite";
      this.promptCallback = () => {
        var origin = this.feuille.feuille_courante ? "courante" : "precedente"
        let data = { feuille_id: this.feuille.id, origin: origin }
        this.soumettreFeuilleDeTemps(data);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
    approuverBtnClick() {
      this.promptTitle = "Approuver feuille de temps";
      this.promptText =
        "Êtes vous sûr de vouloir approuver cette feuille de temps? Elle ne pourra plus être modifiée par la suite";
      this.promptCallback = () => {
        var origin = this.feuille.feuille_courante ? "courante" : "precedente"
        let data = { feuille_id: this.feuille.id, origin: origin }
        this.approuverFeuilleDeTemps(data);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
    declinerBtnClick() {
      this.promptTitle = "Rejeter feuille de temps";
      this.promptText =
        "Êtes vous sûr de vouloir rejeter cette feuille de temps? Elle pourra modifiée et soumise à nouveau";
      this.promptCallback = () => {
        var origin = this.feuille.feuille_courante ? "courante" : "precedente"
        let data = { feuille_id: this.feuille.id, origin: origin }
        this.rejeterFeuilleDeTemps(data);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
    excelBtnClick() {
      this.fetchFeuilleExcel(this.feuille.id).then((result) => {
        if (result != null) {
          var link = this.$refs.excelDownloadLink;
          link.href = result;
          link.click();
        }
      });
    },

    setFilteredEntrees(newValue) {
      this.feuilleEntreesFiltered = newValue;
    },

    intervalChanged(interval) {
      this.fetchFeuilleEntrees(this.feuille.id).then((response) => {
        if (response != null) {
          var filteredEntrees = response.reduce((accumulator, e) => {
            let date_debut = interval.date_debut
            let date_fin = interval.date_fin
            let dateEntree = e.date;

            if (date_debut != null) {
              if (date_fin != null) {
                if (dateEntree >= date_debut && dateEntree <= date_fin)
                  accumulator.push(e)
              }
              else {
                if (dateEntree >= date_debut)
                  accumulator.push(e)
              }
            }
            else {
              if (date_fin != null) {
                if (dateEntree <= date_fin)
                  accumulator.push(e)
              }
            }

            return accumulator
          }, [])
          this.feuilleEntreesFiltered = filteredEntrees
          this.feuilleEntrees=filteredEntrees
          this.setEntrees(filteredEntrees)
        }
      });
    },
    formatDateTime(date) {
      if (date != undefined) {
        var dateObject = this.$datetime.fromISO(date);
        return dateObject.toFormat("yyyy-MM-dd HH:mm");
      } else return "";
    },
    statutClass(statut) {
      return this.getStatutClass()(statut);
    },
  },
  created() {
    this.fetchFeuilleById(this.feuille_id).then((f) => {
      this.feuille = f;

      this.fetchFeuilleEntrees(this.feuille_id).then((e) => {
        this.feuilleEntrees = e;
        this.feuilleEntreesFiltered = e
      });

      this.fetchPeriode(this.feuille.periode).then((p) => {
        this.periode = p;
      });
    });

    this.fetchUserApprobations(this.getUser().id).then((a) => {
      this.userApprobations = a;
    });
    this.fetchProjets().then((p) => {
      this.projets = p;
    });
    this.fetchTaches().then((t) => {
      this.taches = t;
    });
  },
};
</script>