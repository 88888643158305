import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import LoginTwoFactor from '../views/LoginTwoFactor.vue'
import Verification from '../views/Verification.vue'
import Logout from '../views/Logout.vue'
//import Form from '../views/Form.vue'
import Feuille from '../views/Feuille.vue'
import Dashboard from '../views/Dashboard.vue'
import Timesheet from '../views/Timesheet.vue'
import TimesheetSingle from '../views/TimesheetSingle.vue'
import PasswordReset from '../views/PasswordReset.vue'
import PasswordResetAction from '../views/PasswordResetAction.vue'


import AdminDashboard from '../views/Admin/AdminDashboard.vue'
import AdminRETD from "../views/Admin/AdminRETD.vue"
import AdminUtilisateurs from "../views/Admin/AdminUtilisateurs.vue"
import AdminProjets from "../views/Admin/AdminProjets.vue"
import AdminEntrees from "../views/Admin/AdminEntrees.vue"
//import AdminSalaires from "../views/Admin/AdminSalaires.vue"
import AdminTimesheet from "../views/Admin/AdminTimesheet.vue"
import AdminPrioritesProjetsRetD from "../views/Admin/AdminPrioritesProjetsRetD.vue"


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        meta: { requiresAuth: false }
    },
    {
        path: '/feuille',
        name: 'Feuille',
        component: Feuille,
        meta: { requiresAuth: true }
    },
    {
        path: '/connexion',
        name: 'Login',
        component: Login,
        meta: { requiresAuth: false }
    },
    {
        path: '/connexion/doublefacteur',
        name: 'LoginTwoFactor',
        component: LoginTwoFactor
    },
    {
        path: '/verification',
        name: 'Verification',
        component: Verification
    },
    {
        path: '/deconnexion',
        name: 'Logout',
        component: Logout,
        meta: { requiresAuth: true }
    },
    {
        path: '/connexion/reinitialiser',
        name: 'PasswordReset',
        component: PasswordReset
    },
    {
        path: '/connexion/reinitialiser/:reinitialisation_link',
        name: 'PasswordResetAction',
        props: true,
        component: PasswordResetAction
    },
    {
        path: '/feuille/:feuille_id',
        name: "Feuille item",
        component: TimesheetSingle,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/feuilles',
        name: "Feuilles de temps",
        component: Timesheet,
        meta: { requiresAuth: true }
    },
    {
        path: '/feuilles/dashboard',
        name: "Dashboard",
        component: Dashboard,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin',
        name: "adminDashboard",
        component: AdminDashboard,
        meta: { requiresAdminAuth: true, requiresAuth: true }
    },
    {
        path: '/admin/feuilles',
        name: "adminTimesheet",
        component: AdminTimesheet,
        meta: { requiresAdminAuth: true, requiresAuth: true }
    },
    {
        path: '/admin/entrees',
        name: "adminEntrees",
        component: AdminEntrees,
        meta: { requiresAdminAuth: true, requiresAuth: true }
    },
    {
        path: '/admin/projetsretd/affectations',
        name: "AdminRetD",
        component: AdminRETD,
        meta: { requiresAdminAuth: true, requiresAuth: true }
    },
    {
        path: '/admin/projets',
        name: "AdminProjets",
        component: AdminProjets,
        meta: { requiresAdminAuth: true, requiresAuth: true }
    },
    {
        path: '/admin/utilisateurs',
        name: 'AdminUtilisateurs',
        component: AdminUtilisateurs,
        meta: { requiresAdminAuth: true, requiresAuth: true }
    },
    {
        path: '/admin/projetsretd/priorites',
        name: 'AdminPrioritesProjetsRetD',
        component: AdminPrioritesProjetsRetD,
        meta: { requiresAdminAuth: true, requiresAuth: true }
    },
    // {
    //     path: '/admin/salaires',
    //     name: 'AdminSalaires',
    //     component: AdminSalaires,
    //     meta: { requiresAdminAuth: true, requiresAuth: true }
    // },
]

const router = new VueRouter({
    routes
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        await store.dispatch("getUserInfo")
        if (store.getters.isLogged) {
            if (to.matched.some(record => record.meta.requiresAdminAuth)) {
                if (store.getters.isAdmin) {
                    next()
                } else {
                    next({ path: '/connexion', query: { redirect: to.fullPath, admin_redirect: true } })
                }
            } else {
                next()
            }
        } else {
            next({ path: '/connexion', query: { redirect: to.fullPath } })
        }
    } else {
        next()
    }
})

export default router