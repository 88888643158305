import axios from "axios"
import { entreesUrl, entreeUrl, allEntreesUrl, feuilleTemplateLoadUrl,feuilleEntreesUrl } from "../urls"

const state = {
    entrees: null,
    projetsList: null,
    selectedProjetEntrees: null,
}

const getters = {
    getEntrees: (state) => {
        return state.entrees
    },
    getProjetsList(state) {
        return state.projetsList
    },
    getSelectedProjetEntrees(state) {
        return state.selectedProjetEntrees
    },
    getSelectedProjetEntreesOrEntrees(state) {
        var entrees
        if (state.selectedProjetEntrees != null) {
            if (state.selectedProjetEntrees.length != 0) {
                entrees = state.selectedProjetEntrees
            } else {
                entrees = state.entrees
            }
        } else {
            entrees = state.entrees
        }
        return entrees
    },
}

const actions = {
    async fetchEntrees({ commit }, user_id) {
        var url = entreesUrl.replace("{id}", user_id);
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setEntrees", reponse.data)
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    async fetchAllEntrees({ commit }, data = null) {
        const reponse = await axios.get(allEntreesUrl, { params: { date_debut: data.date_debut, date_fin: data.date_fin } }).then((reponse) => {
            if (reponse.status == 200) {
                commit("setEntrees", reponse.data)
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error)
            return null
        });
        return reponse;
    },

    async fetchFeuilleEntrees({ commit }, feuille_id) {
        var url = feuilleEntreesUrl.replace("{id}", feuille_id);
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setEntrees", reponse.data)
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    async addEntrees({ commit }, entrees) {
        var url = entreesUrl.replace("{id}", entrees[0].utilisateur)
        var result = await axios.post(url, entrees).then((response) => {
            if (response.status == 201) {
                commit("addEntrees", response.data)
                return true
            } else
                return false
        }).catch((error) => {
            console.log(error.data)
            return false
        });
        return result
    },

    updateEntree: async function ({ commit }, entree) {
        var url = entreeUrl.replace("{id}", entree.id);
        return await axios.put(url, entree).then((response) => {
            if (response.status == 200) {
                var entree = response.data
                commit("updateEntree", entree)
                return true
            }
            return false
        }).catch(error => {
            console.log(error)
        });
    },

    async loadFeuilleTemplate({ commit }) {
        return await axios.post(feuilleTemplateLoadUrl).then((reponse) => {
            if (reponse.status == 201) {
                commit("updateEntrees", reponse.data)
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
    },


    setEntrees({ commit }, entrees) {
        commit("setEntrees", entrees)
    },

    deleteEntree: async function ({ commit }, entree) {
        var url = entreeUrl.replace("{id}", entree.id)
        return axios.delete(url).then((response) => {
            if (response.status == 200) {
                commit("deleteEntree", entree)
                return true
            }
            return false
        }).catch(error => {
            console.log(error)
            return false
        });
    },

}
const mutations = {
    setEntrees: (state, entrees) => {
        state.entrees = entrees
    },

    updateEntrees: (state, entrees) => {
        state.entrees.splice(0,state.entrees.length)
        state.entrees.push(...entrees)
    },
 
    addEntrees(state, entrees) {
        for (var i = 0; i < entrees.length; i++) {
            state.entrees.push(entrees[i])
        }
    },
    updateEntree(state, updatedEntree) {
        var entreeIndex = state.entrees.findIndex(e => e.id == updatedEntree.id)
        var entree = state.entrees[entreeIndex]
        entree.projet = updatedEntree.projet
        entree.tache = updatedEntree.tache
        entree.nombre_heures = updatedEntree.nombre_heures
        entree.date = updatedEntree.date
        entree.nom_projet = updatedEntree.nom_projet
        entree.nom_tache = updatedEntree.nom_tache
    },
    deleteEntree(state, entree) {
        var entreeIndex = state.entrees.findIndex(e => e.id == entree.id);
        state.entrees.splice(entreeIndex, 1);
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}