<template>
  <div class="Dashboard-visuals">
    <v-row>
      <v-col>
        <v-card elevation="1"
          ><v-card-title class="justify-center">Total heures</v-card-title
          ><v-card-text class="font-weight-bold">{{
            totalHeures
          }}</v-card-text></v-card
        >
      </v-col>
      <v-col>
        <v-card elevation="1"
          ><v-card-title class="justify-center">Nombre de taches</v-card-title
          ><v-card-text class="font-weight-bold">{{
            nombreDeTaches
          }}</v-card-text></v-card
        ></v-col
      >
      <v-col>
        <v-card elevation="1"
          ><v-card-title class="justify-center">Nombre de projets</v-card-title
          ><v-card-text class="font-weight-bold">{{
            nombreDeProjets
          }}</v-card-text></v-card
        ></v-col
      >
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="1"
          ><v-card-title class="justify-center"
            >Moyenne des heures par projet</v-card-title
          ><v-card-text class="font-weight-bold">{{
            moyenneHeuresProjet
          }}</v-card-text></v-card
        ></v-col
      >
      <v-col>
        <v-card elevation="1"
          ><v-card-title class="justify-center"
            >Moyenne des heures par taches</v-card-title
          ><v-card-text class="font-weight-bold">{{
            moyenneHeuresTache
          }}</v-card-text></v-card
        >
      </v-col>
    </v-row>
    <br />
    <div class="d-flex flex-column align-center">
      <div>
        <h2>Proportions des heures par projet</h2>
        <br />
        <apexchart
          width="700"
          type="donut"
          :options="heuresParProjetData.options"
          :series="heuresParProjetData.series"
        ></apexchart>
      </div>
      <br />
      <div>
        <h2>Proportions des heures par tache</h2>
        <br />
        <apexchart
          width="800"
          type="donut"
          :options="heuresParTacheData.options"
          :series="heuresParTacheData.series"
        ></apexchart>
      </div>
      <br />
      <div>
        <h2>Nombre d'heures par mois</h2>
        <br />
        <apexchart
          width="600"
          type="bar"
          :options="heuresParMoisData.options"
          :series="heuresParMoisData.series"
        ></apexchart>
      </div>
      <br/>
    </div>
  </div>
</template>

<script>
export default {
  name: "VizualisationsComponent",
  computed: {
    projetsDictionary() {
      var dictionary = {};
      this.projets.map((p) => {
        dictionary[p.id] = p;
      });
      return dictionary;
    },
    totalHeures() {
      let totalHeures = this.entrees.reduce((total, entree) => {
        total += entree.nombre_heures;
        return total;
      }, 0);
      return totalHeures.toFixed(2);
    },
    nombreDeTaches() {
      var result = this.entrees.reduce((accumulator, entree) => {
        let id_tache = entree.tache;
        if (!accumulator.includes(id_tache)) {
          accumulator.push(id_tache);
        }
        return accumulator;
      }, []);
      return result.length;
    },
    nombreDeProjets() {
      var result = this.entrees.reduce((accumulator, entree) => {
        let id_projet = entree.projet;
        if (!accumulator.includes(id_projet)) {
          accumulator.push(id_projet);
        }
        return accumulator;
      }, []);
      return result.length;
    },
    moyenneHeuresProjet() {
      return (this.totalHeures / this.nombreDeProjets).toFixed(2);
    },
    moyenneHeuresTache() {
      return (this.totalHeures / this.nombreDeTaches).toFixed(2);
    },
    heuresParTacheData() {
      var result = this.entrees.reduce((accumulator, entree) => {
        let id_tache = entree.tache;
        let nom_tache = this.taches.find((t) => t.id == id_tache).nom_tache;
        let nb_heures = parseFloat(entree.nombre_heures);
        if (nom_tache in accumulator) {
          accumulator[nom_tache] += nb_heures;
        } else {
          accumulator[nom_tache] = nb_heures;
        }
        return accumulator;
      }, []);
      var labels = Object.keys(result);
      var series = Object.values(result);
      return { options: { labels: labels }, series: series };
    },
    heuresParProjetData() {
      var projets = this.projets;
      var result = this.entrees.reduce((accumulator, entree) => {
        let id_projet = entree.projet;
        let nom_projet = projets.find((p) => p.id == id_projet).nom_projet;
        let nb_heures = parseFloat(entree.nombre_heures);
        if (nom_projet in accumulator) {
          accumulator[nom_projet] += nb_heures;
        } else {
          accumulator[nom_projet] = nb_heures;
        }
        return accumulator;
      }, []);
      var labels = Object.keys(result);
      var series = Object.values(result);
      return { options: { labels: labels }, series: series };
    },
    heuresParMoisData() {
      var categories=[]
      var result = this.entrees.reduce((accumulator, entree) => {
        let date = entree.date;
        let nb_heures = parseFloat(entree.nombre_heures);
        var monthString = this.$datetime
          .fromISO(date)
          .toLocaleString({ month: "long", year: "numeric" });
        let entryIndex = accumulator.findIndex((e) => {
          return e.x == monthString;
        });
        if (entryIndex == -1) {
          var histogramEntry = { x: monthString, y: nb_heures };
          accumulator.push(histogramEntry);
          categories.push(monthString)
        } else {
          accumulator[entryIndex].y += nb_heures;
        }
        return accumulator;
      }, []);
      return {
        series: [{ name: "nombre d'heures", data: result }],
        options: {
          dataLabels: {

            formatter: (value) => {
              return value.toFixed(2);
            },
          },
          xaxis: { type: "category",categories:categories,labels:{show:true,},floating:false},
          yaxis: {
            type: "numeric",
            labels: {
              formatter: (value) => {
                return value.toFixed(2);
              },
            },
          },
        },
      };
    },
  },
  props: ["entrees", "projets","taches"],
};
</script>