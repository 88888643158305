<template>
  <div>
    <h1>Plateforme de gestion de feuilles de temps</h1>
    <div class="banner">
      <img src="../assets/banner.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  created()
  {
    this.$router.push({ name: 'Feuille' })
  }
};
</script>
