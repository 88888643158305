import axios from "axios"
import { periodeSalairesUrl } from "../urls"

const state = {
    salaires: null,
}

const getters = {
    getSalaires: (state) => {
        return state.salaires
    },
}

const actions = {
    async fetchPeriodeSalaires({ commit }, id_periode) {
        var url = periodeSalairesUrl.replace("{id}", id_periode);
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setSalaires", reponse.data)
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

}
const mutations = {
    setSalaires: (state, salaires) => {
        state.salaires = salaires
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}