<template>
    <AdminEntreesComponent></AdminEntreesComponent>
</template>

<script>
import AdminEntreesComponent from "@/components/AdminEntreesComponent.vue"
export default {
    name:"AdminEntrees",
    components:{AdminEntreesComponent}
}
</script>