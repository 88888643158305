import axios from "axios"
import { affectationsUrl, affectationUrl, projetsRetDUrl, usersUrl,prioritesProjetsRetDUrl,prioriteProjetsRetDUrl } from "../urls"

const state = {
    projetsRetD: null,
    utilisateurs: null,
    affectations: null,
    priorites:null,
    priorite:null
}

const getters = {
    getAffectations: (state) => {
        return state.affectations
    },
    getProjetsRetD: (state) => {
        return state.projetsRetD
    },
    getProjetRetDById: (state, id) => {
        return state.projetsRetD.find((p) => { p.id == id });
    },
    getPriorites: (state) => {
        return state.priorites;
    },

}

const actions = {
    async fetchProjetsRetD({ commit }) {
        const reponse = await axios.get(projetsRetDUrl).then((reponse) => {
            if (reponse.status == 200) {
                commit("setProjets", reponse.data);
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    async fetchUtilisateurs({ commit }) {
        const reponse = await axios.get(usersUrl).then((reponse) => {
            if (reponse.status == 200) {
                commit("setUtilisateurs", reponse.data);
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    async fetchAffectations({ commit }) {
        const reponse = await axios.get(affectationsUrl).then((reponse) => {
            if (reponse.status == 200) {
                commit("setAffectations", reponse.data);
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },
    
    async fetchPriorites({ commit }) {
        const reponse = await axios.get(prioritesProjetsRetDUrl).then((reponse) => {
            if (reponse.status == 200) {
                commit("setPriorites", reponse.data);
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },
    
    async fetchPriorite({ commit },id) {
        var url = prioriteProjetsRetDUrl.replace("{id}", id)
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setPriorite", reponse.data);
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    addPriorite({ commit }, priorite) {
        var reponse = axios.post(prioritesProjetsRetDUrl, priorite).then((reponse) => {
            if (reponse.status == 201) {
                commit("addPriorite", reponse.data);
                return reponse.data
            }
        })
        return reponse
    },
    
    updatePriorite({ commit }, priorite) {
        var url = prioriteProjetsRetDUrl.replace("{id}", priorite.id)
        var reponse = axios.put(url, priorite).then((reponse) => {
            if (reponse.status == 200) {
                commit("updatePriorite", reponse.data);
                return true
            }
            return false
        })
        return reponse
    },

    addAffectation({ commit }, affectation) {
        var reponse = axios.post(affectationsUrl, affectation).then((reponse) => {
            if (reponse.status == 201) {
                commit("addAffectation", reponse.data);
                return reponse.data
            }
        })
        return reponse
    },

    updateAffectation({ commit }, affectation) {
        var url = affectationUrl.replace("{id}", affectation.id)
        var reponse = axios.put(url, affectation).then((reponse) => {
            if (reponse.status == 200) {
                commit("updateAffectation", reponse.data);
                return reponse.data
            }
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse
    },

    //eslint-disable-next-line no-unused-vars
    deleteAffectation: function({ commit }, affectation) {
        var url = affectationUrl.replace("{id}", affectation.id)
        axios.delete(url).then((response) => {
            if (response.status == 200) {
                commit("deleteAffectation", affectation);
                return true
            } else {
                return false
            }
        }).catch((error) => {
            console.log(error.data)
            return false
        });
    },
    
    //eslint-disable-next-line no-unused-vars
    deletePriorite: function({ commit }, priorite) {
        var url = prioriteProjetsRetDUrl.replace("{id}", priorite.id)
        axios.delete(url).then((response) => {
            if (response.status == 200) {
                commit("deletePriorite", priorite);
                return true
            } else {
                return false
            }
        }).catch((error) => {
            console.log(error.data)
            return false
        });
    },

}
const mutations = {
    setProjetsRetD: (state, projets) => { state.projetsRetD = projets },
    setUtilisateurs: (state, utilisateurs) => { state.utilisateurs = utilisateurs },
    setAffectations: (state, affectations) => { state.affectations = affectations },
    setPriorites: (state, priorites) => { state.priorites = priorites },
    setPriorite: (state, priorite) => { state.priorite = priorite }, 
    addAffectation: (state, affectation) => { state.affectations.push(affectation) },
    addPriorite: (state, priorite) => { state.priorites.push(priorite) },
    updateAffectation: (state, affectation) => {
        var index = state.affectations.findIndex(a => a.id == affectation.id)
        state.affectations[index] = affectation
    },
    updatePriorite: (state, updatedPriorite) => {
        var index = state.priorites.findIndex(p => p.id == updatedPriorite.id)
        state.priorites[index] = updatedPriorite
    },
    deleteAffectation: (state, affectation) => {
        var index = state.affectations.findIndex(a => a.id == affectation.id)
        state.affectations.splice(index, 1)
    },
    deletePriorite: (state, priorites) => {
        var index = state.priorites.findIndex(p=> p.id == priorites.id)
        state.priorites.splice(index, 1)
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}