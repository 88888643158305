<template>
  <div>
    <NavMenuComponent :menuData="menuData"></NavMenuComponent>
    <h1>Projets R&D</h1>
    <br />
    <hr />
    <br />
    <MatriceAffectationsComponent></MatriceAffectationsComponent>
  </div>
</template>

<script>
import NavMenuComponent from "./NavMenuComponent.vue";
import MatriceAffectationsComponent from "./MatriceAffectationsComponent.vue";
export default {
  name: "ProjetsRetDComponent.vue",
  components: { MatriceAffectationsComponent, NavMenuComponent },
  data: () => ({
    menuData: [
      {
        title: "Affectations projets R&D",
        link: "/admin/projetsretd/affectations",
      },
      {
        title: "Priorités projets R&D",
        link: "/admin/projetsretd/priorites",
      },
    ],
  }),
};
</script>