<template>
  <v-container>
    <div>
      <h1>Entrées Utilisateurs</h1>
      <div v-if="dataFetched">
        <EntreesTableComponent :entrees="entrees" :projets="projets" :taches="taches" :headers="headers"
          v-on:intervalChanged="intervalChanged"></EntreesTableComponent>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import EntreesTableComponent from "./EntreesTableComponent";
export default {
  name: "AdminDashboardComponent.vue",
  data: () => ({
    entrees: null,
    projets: null,
    taches: null,
    date_debut: null,
  }),
  computed: {
    headers() {
      return [
        {
          text: "Projet",
          value: "nom_projet",
          width: "250px",
          groupable: true,
        },
        { text: "Tache", value: "nom_tache", width: "200px" },
        { text: "Utilisateur", value: "nom_utilisateur" },
        { text: "Date", value: "date" },
        { text: "Date de la saisie", value: "created" },
        { text: "Heures", value: "nombre_heures" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    dataFetched() {
      return this.entrees != null && this.projets != null && this.taches != null;
    },
  },
  methods: {
    ...mapActions(["fetchAllEntrees", "fetchProjets", "fetchTaches"]),
    intervalChanged(data) {
      this.fetchAllEntrees(data.interval).then((response) => {
        if (response != null) {
          this.entrees = response
          data.callback()
        }
      });
    },
  },
  components: { EntreesTableComponent },
  created() {
    let currentDate = this.$datetime.now();
    let oneMonthAgo = currentDate.minus({ months: 1 });
    let dateString = oneMonthAgo.toISODate();
    this.date_debut = dateString;
    let data = { date_debut: dateString };

    this.fetchAllEntrees(data).then((e) => {
      this.entrees = e;
    });
    this.fetchProjets().then((p) => {
      this.projets = p;
    });
    this.fetchTaches().then((t) => {
      this.taches = t;
    });
  },
};
</script>