var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fetching),expression:"!fetching"}]},[_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"label":"Afficher la table des entrées"},model:{value:(_vm.tableSwitch),callback:function ($$v) {_vm.tableSwitch=$$v},expression:"tableSwitch"}})],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Récaptitulatif par journée"},model:{value:(_vm.perDayTableSwitch),callback:function ($$v) {_vm.perDayTableSwitch=$$v},expression:"perDayTableSwitch"}})],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Afficher graphiques"},model:{value:(_vm.graphicsSwitch),callback:function ($$v) {_vm.graphicsSwitch=$$v},expression:"graphicsSwitch"}})],1)],1),_c('br'),_c('br'),_c('EntreesFilterComponent',{attrs:{"entrees":_vm.entrees,"customPeriode":_vm.periode},on:{"entreesFiltered":_vm.setFilteredEntrees,"periodeChanged":_vm.periodeChanged}}),_c('br'),_c('hr'),_c('br'),_c('br'),_c('br'),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.perDayTableSwitch),expression:"perDayTableSwitch"}],attrs:{"sort-by":"date","sort-desc":true,"items":_vm.entreesParJour,"headers":_vm.entreesParJourHeaders,"no-data-text":"Aucune donnée disponible","no-results-text":"Aucun résultat correspondant","footer-props":{
        'items-per-page-text': 'Lignes par page:',
        'page-text': '{0}-{1} sur {2}',
        'items-per-page-all-text': 'Toutes',
      }}}),_c('br'),(_vm.tableSwitch)?_c('v-data-table',{attrs:{"locale":"fr","items":_vm.filteredEntrees,"headers":_vm.headers,"sort-by":"created","sort-desc":true,"no-data-text":"Aucune donnée disponible","no-results-text":"Aucun résultat correspondant","footer-props":{
        'items-per-page-text': 'Lignes par page:',
        'page-text': '{0}-{1} sur {2}',
        'items-per-page-all-text': 'Toutes',
      }},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.created))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('td',[_c('TimesheetEditComponent',{attrs:{"projets":_vm.projets,"taches":_vm.taches,"entree":item}}),_c('v-btn',{attrs:{"x-small":"","color":"red","icon":""},on:{"click":function($event){return _vm.deleteAction(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',[_vm._v("Total")]),_c('td'),_c('td'),_c('td',[_vm._v(_vm._s(_vm.totalHeuresEntrees))]),_c('td')])]},proxy:true}],null,false,4152955856)}):_vm._e(),_c('VizualisationsComponent',{directives:[{name:"show",rawName:"v-show",value:(_vm.graphicsSwitch),expression:"graphicsSwitch"}],attrs:{"entrees":_vm.filteredEntrees,"projets":_vm.projets,"taches":_vm.taches}}),_c('ConfirmationPromptComponent',{ref:"confirmationPrompt",attrs:{"titre":"Confirmer suppression","text":_vm.promptText,"callback":_vm.promptCallback}})],1),_c('br'),_c('br'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.fetching),expression:"fetching"}]},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"100"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }