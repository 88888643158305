<template>
  <div class="col-md-4" style="margin: 0 auto">
    <div v-if="!emailVerification">
      <h1>Vérification adresse email</h1>
      <br />
      <p>Un code vous a été envoyé a votre adresse mail pour vérification</p>
      <v-alert type="error" v-if="error">Erreur, code invalide ou expiré</v-alert>
      <v-form @submit="submitVerifyEmail">
        <div>
          <div v-if="$v.codeEmail.$dirty">
            <v-alert class="error" v-if="!$v.codeEmail.required">
              Ce champ est requis
            </v-alert>
          </div>
          <v-text-field v-model="$v.codeEmail.$model" type="number" label="Code de vérification">
          </v-text-field>
        </div>
        <v-btn type="submit">Vérifier</v-btn>
      </v-form>
      <br />
      <div>
        <v-btn @click="resendCodeEmail">Renvoyer le code</v-btn>
      </div>
      <v-snackbar v-model="snackbar">
        Code renvoyé
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Fermer
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <div v-if="emailVerification">
      <h1>Vérification numéro téléphone</h1>
      <br />
      
      <div v-if="!isUserNumberSent">
        <p>Veuillez rentrer votre numéro de téléphone pour vérification</p>
        <v-form @submit.prevent="startTelephoneVerification">
          <div>
            <div v-if="$v.no_telephone.$dirty">
              <v-alert class="error" v-if="!$v.no_telephone.required">
                Ce champ est requis
              </v-alert>
              <v-alert class="error" v-if="!($v.no_telephone.minLength || $v.no_telephone.maxLength)">
                Veuillez entrer un numéro correspondant au format spécifié
              </v-alert>
              <v-alert class="error" v-if="!$v.no_telephone.numeric">
                Veuillez entrer des valeurs numériques
              </v-alert>
            </div>

            <v-text-field v-model="$v.no_telephone.$model" type="number" label="Numéro de téléphone (XXX XXX XXXX)">
            </v-text-field>
          </div>
          <v-btn type="submit">Envoyer</v-btn>
        </v-form>
      </div>

      <div v-if="isUserNumberSent">
        <p>Un code vous a été envoyé par sms pour vérification</p>
        <v-alert type="error" v-if="error">Erreur, code invalide ou expiré</v-alert>
        <v-form @submit="submitVerifyTelephone">
          <div>
            <div v-if="$v.codeSms.$dirty">
              <v-alert class="error" v-if="!$v.codeSms.required">
                Ce champ est requis
              </v-alert>
            </div>
  
            <v-text-field v-model="$v.codeSms.$model" type="number" label="Code de vérification">
            </v-text-field>
          </div>
          <v-btn type="submit">Vérifier</v-btn>
        </v-form>
        <br />
        <div>
          <v-btn @click="resendCodeSms">Renvoyer le code</v-btn>
        </div>
      </div>
    </div>
    <v-snackbar v-model="snackbar">
      Code renvoyé
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required, minLength, maxLength, numeric } from "vuelidate/lib/validators";
export default {
  name: "LoginComponent",
  data: () => ({
    codeEmail: "",
    codeSms: "",
    no_telephone: "",
    emailVerification: false,
    smsVerification: false,
    isUserNumberSent: false,
    snackbar: false,
    error: false,
  }),
  watch: {
    smsVerification(newValue) {
      if (newValue) {
        if (this.emailVerification) {
          this.$router.push({ path: "/connexion" });
        }
      }
    },
  },
  methods: {
    ...mapActions([
      "triggerEmailVerification",
      "verifyEmail",
      "triggerTelephoneVerification",
      "verifyTelephone",
      "resendCodeTwoFactorAuthenticationTelephone",
      "resendCodeTwoFactorAuthenticationEmail",
    ]),
    submitVerifyEmail: function (e) {
      e.preventDefault();
      this.error = false;
      var code = { code: this.codeEmail.toString() };
      this.verifyEmail(code).then((response) => {
        if (response) {
          this.emailVerification = true;
        } else {
          this.error = true;
        }
      });
    },
    submitVerifyTelephone: function (e) {
      e.preventDefault();
      this.error = false;
      var code = { code: this.codeSms.toString() };
      this.verifyTelephone(code).then((response) => {
        if (response) {
          this.smsVerification = true;
        } else {
          this.error = true;
        }
      });
    },
    startTelephoneVerification() {
      this.$v.no_telephone.$touch()
      if (!this.$v.no_telephone.$invalid) {
      this.triggerTelephoneVerification(this.no_telephone).then((response) => {
          if (response) 
          {
            this.isUserNumberSent=true
          }
          else{
            this.error = true;
          }
        })
      }
    },
    resendCodeEmail() {
      this.error = false;
      this.resendCodeTwoFactorAuthenticationEmail().then((response) => {
        if (response) this.snackbar = true;
        else this.error = true;
      });
    },
    resendCodeSms() {
      this.error = false;
      this.resendCodeTwoFactorAuthenticationTelephone().then((response) => {
        if (response) this.snackbar = true;
        else this.error = true;
      });
    },
  },
  validations: {
    codeEmail: {
      required,
    },
    codeSms: {
      required,
    },
    no_telephone:{
      required,
      minLength:minLength(10),
      maxLength:maxLength(10),
      numeric
    }
  },
  created() {
    this.triggerEmailVerification().then((response) => {
      if (!response) {
        this.error = true;
      }
    });
  },
};
</script>