<template>
    <VerificationComponent></VerificationComponent>
</template>

<script>
import VerificationComponent from "@/components/VerificationComponent"
export default {
    name:"Verification",
    components:{VerificationComponent}
}
</script>