<template>
<v-container>
   <AdminPrioritesProjetsRetDComponent class="col-md-8" style="margin:auto"></AdminPrioritesProjetsRetDComponent>
</v-container>
</template>

<script>
import AdminPrioritesProjetsRetDComponent from "../../components/AdminPrioritesProjetsRetDComponent";
export default {
  name: 'AdminPrioritesProjetsRetD',
  components: {
    AdminPrioritesProjetsRetDComponent
  },
}
</script>
