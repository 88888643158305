<template>
  <div id="navMenu">
    <v-row>
      <v-col v-for="(menuDataItem,index) in menuData" :key="index"
        ><router-link :to="menuDataItem.link"
          >{{menuDataItem.title}}</router-link
        ></v-col
      >
    </v-row>
  </div>
</template>

<script>
export default {
  name: "NavMenuComponent",
  props: ["menuData"],
};
</script>

<style scoped>
#navMenu {
  width: 100%;
  margin: 20px;
  background-color: ghostwhite;
  box-shadow: 10px black;
}

@media print
{
  #navMenu
  {
    display: none;
  }
}
</style>