<template>
  <div v-if="dataFetched">
    <div>
      <h1>En attente d'approbation</h1>
      <div class="col-md-6" style="margin: 0 auto">
        <div>
          <TimesheetItemComponent
            v-for="feuille in feuillesEnAttenteApprobation"
            :key="feuille.id"
            :feuille="feuille"
            :showName="true"
            :userApprobations="userApprobations"
            origin="liste"
          ></TimesheetItemComponent>
        </div>
        <div v-if="feuillesEnAttenteApprobation.length == 0">
          <p>
            Aucune feuille de temps ne requiert votre approbation actuellement.
          </p>
        </div>
      </div>
    </div>
    <div>
      <h1>Feuilles de temps</h1>
      <div class="col-md-6" style="margin: 0 auto">
        <div style="text-align: center">
          <v-list>
            <v-list-group :value="false">
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-title>
                    <p>
                      Nombre total: {{ feuilles.length }}
                    </p></v-list-item-title
                  >
                </v-list-item>
              </template>
              <v-list-item>
                <v-list-item-content>
                  <p>Approuvées: {{ feuillesApprouvees.length }}</p>
                  <p>En attente: {{ feuillesEnAttente.length }}</p>
                  <p>Soumises: {{ feuillesSoumis.length }}</p>
                  <p>Brouillon: {{ feuillesBrouillon.length }}</p>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
        <div>
          <v-select
            v-model="periode"
            :items="periodes"
            item-text="nom_periode"
            return-object
            label="Période de paie"
            @change="periodeChange"
          ></v-select>
          <v-text-field v-model="search" label="Recherche"></v-text-field>
        </div>
        <TimesheetItemComponent
          v-for="feuille in feuillesDeTemps"
          :key="feuille.id"
          :feuille="feuille"
          :showName="true"
          origin="liste"
        ></TimesheetItemComponent>
        <div v-show="feuillesDeTemps.length == 0">
          <p>Aucune feuille de temps pour cette période</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TimesheetItemComponent from "./TimesheetItemComponent.vue";
export default {
  name: "AdminTimesheetComponent",
  components: { TimesheetItemComponent },
  methods: {
    ...mapActions([
      "fetchFeuillesDeTempsPeriode",
      "fetchUserApprobations",
      "fetchPeriodes",
    ]),
    ...mapGetters([
      "getUser",
      "isSoumis",
      "isApprouve",
      "isEnAttente",
      "isBrouillon",
    ]),
    periodeChange() {
      this.fetchFeuillesDeTempsPeriode(this.periode.id).then((f) => {
        this.feuilles = f;
      });
    },
  },
  computed: {
    usersToApproveIds() {
      if (this.dataFetched) {
        var usersToApproveIds = this.userApprobations.map((approbation) => {
          return approbation.utilisateur;
        });
        return usersToApproveIds;
      } else return [];
    },
    feuillesEnAttenteApprobation() {
      if (this.dataFetched) {
        var usersToApproveIds = this.usersToApproveIds;
        let soumis = this.isSoumis;
        return this.feuilles.reduce((accumulator, feuille) => {
          if (
            usersToApproveIds.includes(feuille.utilisateur) &&
            soumis()(feuille.statut)
          ) {
            accumulator.push(feuille);
          }
          return accumulator;
        }, []);
      } else return [];
    },
    feuillesDeTemps() {
      if (this.dataFetched) {
        let feuillesEnAttente = this.feuillesEnAttenteApprobation;
        return this.feuilles.filter((f) => {
          var searchResult = true;
          if (this.search != "") {
            searchResult = RegExp(this.search, "i").test(f.nom_utilisateur);
          }
          return !feuillesEnAttente.includes(f) && searchResult;
        });
      } else return [];
    },
    feuillesBrouillon() {
      if (this.dataFetched) {
        return this.feuilles.reduce((accumulator, f) => {
          if (this.isBrouillon()(f.statut)) accumulator.push(f);
          return accumulator;
        }, []);
      } else return [];
    },
    feuillesEnAttente() {
      if (this.dataFetched) {
        return this.feuilles.reduce((accumulator, f) => {
          if (this.isEnAttente()(f.statut)) accumulator.push(f);
          return accumulator;
        }, []);
      } else return [];
    },
    feuillesApprouvees() {
      if (this.dataFetched) {
        return this.feuilles.reduce((accumulator, f) => {
          if (this.isApprouve()(f.statut)) accumulator.push(f);
          return accumulator;
        }, []);
      } else return [];
    },
    feuillesSoumis() {
      if (this.dataFetched) {
        return this.feuilles.reduce((accumulator, f) => {
          if (this.isSoumis()(f.statut)) accumulator.push(f);
          return accumulator;
        }, []);
      } else return [];
    },
  },
  data: () => ({
    dataFetched: false,
    feuilles: null,
    userApprobations: null,
    search: "",
    periode: null,
    périodes: null,
  }),
  created() {
    this.fetchPeriodes().then((p) => {
      this.periodes = p;
      this.periode = p.find((periode) => periode.periode_courante);
      var periode = this.periode;
      this.fetchFeuillesDeTempsPeriode(periode.id).then((f) => {
        this.feuilles = f;
        this.fetchUserApprobations(this.getUser().id).then((a) => {
          this.userApprobations = a;
          this.dataFetched = true;
        });
      });
    });
  },
};
</script>
<style scoped>
.v-list-item__content
{
  text-align: center;
  padding:0px;
}
</style>