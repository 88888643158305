import axios from "axios"
import { projetsUrl, projetUrl, tachesUrl, tacheUrl } from "../urls"

const state = {
    projets: null,
    projet: null,
    taches:null,
}

const getters = {
    getProjets: (state) => {
        return state.projets
    },
    getProjet: (state) => {
        return state.projet;
    },
    getProjetById: (state) => (id) => {
        return state.projets.find((p) => p.id == id);
    },
    getProjetsDictonary(state) {
        var dictionary = {}
        state.projets.map(p => {
            dictionary[p.id] = p
        })
        return dictionary
    },
    getTaches: (state) => {
        return state.projet.taches;
    },
    getTacheById: (state) => (tache_id, projet_id) => {
        var projetIndex = state.projets.findIndex(p => p.id == projet_id);
        return state.projets[projetIndex].taches.find(t => t.id == tache_id);
    }
}

const actions = {
    async fetchProjets({ commit }, ) {
        return await axios.get(projetsUrl).then((reponse) => {
            if (reponse.status == 200) {
                commit("setProjets", reponse.data);
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
    },
    selectProjet({ commit }, projet_id) {
        commit("setProjetById", projet_id);
    },
    async addProjet({ commit }, projet) {
        return await axios.post(projetsUrl, projet).then((response) => {
            if (response.status == 201) {
                var projetData = response.data;
                commit("addProjet", projetData);
                return true
            }
            return false
        }).catch((error) => {
            console.log(error.data)
            return false
        });
    },
    async updateProjet({ commit }, projet) {
        var url = projetUrl.replace("{id}", projet.id);
        return await axios.put(url, projet).then((response) => {
            if (response.status == 200) {
                commit("updateProjet", projet);
                return true
            }
            return false
        }).catch((error) => {
            console.log(error.data)
            return false
        });
    },
    async deleteProjet({ commit }, projet) {
        var url = projetUrl.replace("{id}", projet.id);
        return await axios.delete(url).then((response) => {
            if (response.status == 200) {
                commit("deleteProjet", projet);
                return true
            }
            return false
        }).catch((error) => {
            console.log(error.data)
            return false
        });
    },

    async fetchTaches({ commit }, ) {
        return await axios.get(tachesUrl).then((reponse) => {
            if (reponse.status == 200) {
                commit("setTaches", reponse.data);
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
    },

    async addTache({ commit }, tache) {
        return await axios.post(tachesUrl, tache).then((response) => {
            if (response.status == 201) {
                var tacheData = response.data;
                commit("addTache", tacheData);
                return true
            }
            return false
        }).catch((error) => {
            console.log(error.data)
            return false
        });
    },
    async updateTache({ commit }, tache) {
        var url = tacheUrl.replace("{id}", tache.id);
        return await axios.put(url, tache).then((response) => {
            if (response.status == 200) {
                commit("updateTache", tache);
                return true
            }
            return false
        }).catch((error) => {
            console.log(error.data)
            return false
        });
    },
    async deleteTache({ commit }, tache) {
        var url = tacheUrl.replace("{id}", tache.id);
        return await axios.delete(url).then((response) => {
            if (response.status == 200) {
                commit("deleteTache", tache);
                return true
            }
            return false
        }).catch((error) => {
            console.log(error)
            return false
        });
    },

}
const mutations = {
    setProjets: (state, projets) => { state.projets = projets },
    setProjetById: (state, projet_id) => { state.projet = state.projets.find(p => p.id == projet_id) },

    addProjet: (state, projet) => {
        state.projets.push(projet);
    },
    updateProjet: (state, updatedProjet) => {
        var projetIndex = state.projets.findIndex(p => p.id == updatedProjet.id);
        var projet = state.projets[projetIndex]
        projet.nom_projet = updatedProjet.nom_projet
    },
    deleteProjet: (state, projet) => {
        var projetIndex = state.projets.findIndex(p => p.id == projet.id);
        state.projets.splice(projetIndex, 1);
    },
    setTaches: (state, taches) => { state.taches = taches },
    addTache: (state, tache) => {
        var projet_id = tache.projet;
        var projetIndex = state.projets.findIndex(p => p.id == projet_id);
        state.projets[projetIndex].taches.push(tache);
        state.taches.push(tache)
    },
    updateTache: (state, updatedTache) => {
        var projet_id = updatedTache.projet;
        var projetIndex = state.projets.findIndex(p => p.id == projet_id);
        var tacheIndex = state.projets[projetIndex].taches.findIndex(t => t.id == updatedTache.id);
        var tache = state.projets[projetIndex].taches[tacheIndex]
        tache.nom_tache = updatedTache.nom_tache
    },
    deleteTache: (state, tache) => {
        var projet_id = tache.projet;
        var projetIndex = state.projets.findIndex(p => p.id == projet_id);
        var tacheIndex = state.projets[projetIndex].taches.findIndex(t => t.id == tache.id);
        state.projets[projetIndex].taches.splice(tacheIndex, 1)
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}