import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

import fr from 'vuetify/lib/locale/fr.js'

Vue.component('v-data-table', {
    methods: {
        changeLocale() {
            this.$vuetify.lang.current = 'fr'
        },
    },
})

const opts = {
    lang: {
        locales: { fr },
        current: "fr"
    }
};

export default new Vuetify({ opts });