import axios from "axios"
import { activateUserUrl, deactivateUserUrl, loginUrl, logoutUrl, userUrl, userInfosUrl, usersUrl, userAffectedProjetsUrl, registerUrl, registerMainAdminUrl, registerSecondaryAdminUrl, userUpdatePasswordUrl, approbationsUrl, approbationUrl, resetPasswordCheckLinkUrl, resetPasswordActionUrl, resetPasswordDemandUrl, verifyEmailUrl, verifyTelephoneUrl, verifyEmailResendCodeUrl, verifyTelephoneResendCodeUrl, twoFactorStepOneloginUrl, twoFactorStepTwologinUrl, twoFactorResendCodeUrl } from "../urls"

const state = {
    user: null,
    users: null
}

const getters = {
    getUser(state) {
        var user = state.user
        return user
    },
    getUsers(state) {
        return state.users
    },
    getUserFromUsersList(state) {
        return (id) => {
            return state.users.find(u => u.id == id)
        }
    },
    isLogged(state, getters) {
        if (getters.getUser == null) {
            return false
        } else {
            return true
        }
    },
    isAdmin(state, getters) {
        var user = getters.getUser
        if (user != null) {
            return getters.getUser.is_staff
        } else {
            return false
        }
    },
    isMainAdmin(state, getters) {
        var user = getters.getUser
        if (user != null) {
            return getters.getUser.is_main_admin
        } else {
            return false
        }
    },
    isSecondaryAdmin(state, getters) {
        var result = false
        if (getters.isAdmin) {
            if (!getters.isMainAdmin) {
                result = true
            }
        }
        return result
    },

    isMainOrSecondaryAdmin(state, getters) {
        return getters.isMainAdmin | getters.isSecondaryAdmin
    },

    isAffectedToRetD(state, getters) {
        var user = getters.getUser
        return user.is_affected_to_retd
    },

    //eslint-disable-next-line no-unused-vars
    isUserAdmin: (state) => (user) => {
        return user.is_staff
    },

    //eslint-disable-next-line no-unused-vars
    isUserMainAdmin: (state) => (user) => {
        return user.is_main_admin
    },

    //eslint-disable-next-line no-unused-vars
    isUserSecondaryAdmin: (state) => (user) => {
        var result = false
        if (user.is_staff) {
            if (!user.is_main_admin) {
                result = true
            }
        }
        return result
    },

    //eslint-disable-next-line no-unused-vars
    isUserMainOrSecondaryAdmin: (state) => (user) => {
        return user.is_staff || user.is_main_admin
    },
}
const actions = {
    //eslint-disable-next-line no-unused-vars
    async fetchUser({ commit }, credentials) {
        return await axios.post(loginUrl, credentials, { withCredentials: true }).then((reponse) => {
            if (reponse.status == 200) {
                const user = reponse.data.user
                commit("setUser", user);
                return { "success": true, "activate": false }
            }
            else if (reponse.status == 202) {
                return { "success": false, "activate": true }
            }
        }).catch(() => {
            return { "success": false };
        });
    },

    //eslint-disable-next-line no-unused-vars
    async fetchUserTwoFactorStepOne({ commit }, credentials) {
        return await axios.post(twoFactorStepOneloginUrl, credentials).then((reponse) => {
            if (reponse.status == 200) {
                return { "success": true, "activate": false }
            }
            else if (reponse.status == 202) {
                return { "success": false, "activate": true }
            }
        }).catch(() => {
            return { "success": false };
        });
    },

    //eslint-disable-next-line no-unused-vars
    async fetchUserTwoFactorStepTwo({ commit }, code) {
        return await axios.post(twoFactorStepTwologinUrl, code).then((reponse) => {
            if (reponse.status == 200) {
                const user = reponse.data.user
                commit("setUser", user);
                return user
            }
        }).catch(() => {
            return null;
        });
    },

    //eslint-disable-next-line no-unused-vars
    async resendCodeTwoFactorAuthentication({ commit }) {
        return await axios.post(twoFactorResendCodeUrl).then((reponse) => {
            if (reponse.status == 200) {
                return true
            }
        }).catch(() => {
            return false;
        });
    },

    async getUserInfo({ commit }) {
        return await axios.get(userInfosUrl).then((reponse) => {
            if (reponse.status == 200) {
                const user = reponse.data
                commit("setUser", user);
                return user
            }
        }).catch(() => {
            return null;
        });
    },

    async fetchUsers({ commit }) {
        var users = await axios.get(usersUrl).then((reponse) => {
            if (reponse.status == 200) {
                var users = reponse.data
                commit("setUsers", users);
                return users;
            }
            return null;
        }).catch(() => {
            return null;
        });
        return users;
    },

    //eslint-disable-next-line no-unused-vars
    async fetchUserAffectationsProjets({ commit }, id) {
        var url = userAffectedProjetsUrl.replace("{id}", id);
        var projets = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                return reponse.data;
            }
            return null;
        }).catch(() => {
            return null;
        });
        return projets;
    },

    //eslint-disable-next-line no-unused-vars
    async fetchUserData({ commit }, id) {
        var url = userUrl.replace("{id}", id);
        var user = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                var user = reponse.data
                return user;
            }
            return null;
        }).catch(() => {
            return null;
        });
        return user;
    },

    async registerMainAdminUser({ commit }, user) {
        var result = await axios.post(registerMainAdminUrl, user).then((reponse) => {
            if (reponse.status == 201) {
                var user = reponse.data
                commit("addUser", user);
                return user;
            }
            return null;
        }).catch(() => {
            return null;
        });
        return result;
    },

    async registerSecondaryAdminUser({ commit }, user) {
        var result = await axios.post(registerSecondaryAdminUrl, user).then((reponse) => {
            if (reponse.status == 201) {
                var user = reponse.data
                commit("addUser", user);
                return user;
            }
            return null;
        }).catch(() => {
            return null;
        });
        return result;
    },

    async registerUser({ commit }, user) {
        var result = await axios.post(registerUrl, user).then((reponse) => {
            if (reponse.status == 201) {
                var user = reponse.data
                commit("addUser", user);
                return user;
            }
            return null;
        }).catch(() => {
            return null;
        });
        return result;
    },

    async activateUser({ commit }, user) {
        let url = activateUserUrl.replace("{id}", user.id)
        return await axios.post(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("activateUser", user.id);
                return true;
            }
            return false;
        }).catch((e) => {
            console.log(e)
            return false;
        });
    },

    async deactivateUser({ commit }, user) {
        let url = deactivateUserUrl.replace("{id}", user.id)
        return await axios.post(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("deactivateUser", user.id);
                return true;
            }
            return false;
        }).catch((e) => {
            console.log(e)
            return false;
        });
    },


    async addApprobateurFDT({ commit }, approbation) {
        var result = await axios.post(approbationsUrl, approbation).then((reponse) => {
            if (reponse.status == 201) {
                var approbation = reponse.data
                commit("updateUserApprobateur", approbation);
                return true;
            }
            return false;
        }).catch(() => {
            return false;
        });
        return result;
    },

    async updateApprobateurFDT({ commit }, approbation) {
        var url = approbationUrl.replace("{id}", approbation.utilisateur);
        var result = await axios.put(url, approbation).then((reponse) => {
            if (reponse.status == 201) {
                var approbation = reponse.data
                commit("updateUserApprobateur", approbation);
                return true
            }
            return false;
        }).catch(() => {
            return false;
        });
        return result;
    },

    async deleteApprobateurFDT({ commit }, approbation) {
        var url = approbationUrl.replace("{id}", approbation.utilisateur);
        var result = await axios.delete(url, approbation).then((reponse) => {
            if (reponse.status == 200) {
                commit("deleteUserApprobateur", approbation);
                return true
            }
            return false;
        }).catch(() => {
            return false;
        });
        return result;
    },


    //eslint-disable-next-line no-unused-vars
    async updateUser({ commit }, user) {
        var url = userUrl.replace("{id}", user.id);
        return await axios.put(url, user).then((response) => {
            if (response.status == 200) {
                commit("updateUser", response.data);
                return true
            }
            return false
        }).catch((error) => {
            console.log(error);
            return false;
        });
    },

    //eslint-disable-next-line no-unused-vars
    async updateUserPassword({ commit }, data) {
        var url = userUpdatePasswordUrl.replace("{id}", data.user_id);
        return await axios.put(url, data).then((response) => {
            if (response.status == 200) {
                return true
            }
            return false
        }).catch((error) => {
            console.log(error)
            return false;
        });
    },

    //eslint-disable-next-line no-unused-vars
    async resetPasswordDemand({ commit }, data) {
        var result = await axios.post(resetPasswordDemandUrl, data).then((response) => {
            if (response.status == 201) {
                return true
            }
            return false
        }).catch((error) => {
            console.log(error)
            return false;
        });
        return result
    },

    //eslint-disable-next-line no-unused-vars
    async resetPasswordAction({ commit }, data) {
        var result = await axios.post(resetPasswordActionUrl, data).then((response) => {
            if (response.status == 200) {
                return true
            }
            return false
        }).catch((error) => {
            console.log(error)
            return false;
        });
        return result
    },

    //eslint-disable-next-line no-unused-vars
    async checkResetPasswordLink({ commit }, data) {
        var result = await axios.post(resetPasswordCheckLinkUrl, data).then((response) => {
            if (response.status == 200) {
                return response.data
            }
            return null
        }).catch((error) => {
            console.log(error)
            return null;
        });
        return result
    },

    //eslint-disable-next-line no-unused-vars
    async triggerEmailVerification() {
        return await axios.get(verifyEmailUrl).then((reponse) => {
            if (reponse.status == 201) {
                return true;
            }
            return false;
        }).catch(() => {
            return false;
        });
    },

    //eslint-disable-next-line no-unused-vars
    async verifyEmail({ commit }, payload) {
        return await axios.post(verifyEmailUrl, payload).then((reponse) => {
            if (reponse.status == 200) {
                return true;
            }
            return false;
        }).catch(() => {
            return false;
        });
    },

    //eslint-disable-next-line no-unused-vars
    async triggerTelephoneVerification({ commit }, no_telephone) {
        return await axios.get(verifyTelephoneUrl, { params: { telephone: no_telephone } }).then((reponse) => {
            if (reponse.status == 201) {
                return true;
            }
            return false;
        }).catch(() => {
            return false;
        });
    },

    //eslint-disable-next-line no-unused-vars
    async verifyTelephone({ commit }, payload) {
        return await axios.post(verifyTelephoneUrl, payload).then((reponse) => {
            if (reponse.status == 200) {
                return true;
            }
            return false;
        }).catch(() => {
            return false;
        });
    },

    //eslint-disable-next-line no-unused-vars
    async resendCodeTwoFactorAuthenticationEmail({ commit }) {
        return await axios.post(verifyEmailResendCodeUrl).then((reponse) => {
            if (reponse.status == 200) {
                return true
            }
        }).catch(() => {
            return false;
        });
    },

    //eslint-disable-next-line no-unused-vars
    async resendCodeTwoFactorAuthenticationTelephone({ commit }) {
        return await axios.post(verifyTelephoneResendCodeUrl).then((reponse) => {
            if (reponse.status == 200) {
                return true
            }
        }).catch(() => {
            return false;
        });
    },

    deleteUser({ commit }, user) {
        var url = userUrl.replace("{id}", user.id);
        axios.delete(url).then((response) => {
            if (response.status == 200) {
                commit("deleteUser", user);
            }
        });
    },

    async logout({ commit }) {
        return await axios.post(logoutUrl).then((reponse) => {
            if (reponse.status == 200) {
                commit("logout");
                return true
            }
        }).catch(() => {
            return false;
        });
    },

    setUser({ commit }, user) {
        commit("setUser", user);
    },

    setUsers: ({ commit }, users) => {
        commit("setUsers", users);
    },

}
const mutations = {
    setUser: (state, user) => {
        state.user = user
    },
    setUsers: (state, users) => {
        state.users = users
    },
    addUser: (state, user) => {
        state.users.push(user)
    },
    updateUser: (state, updatedUser) => {
        var userIndex = state.users.findIndex(u => u.id == updatedUser.id)
        var user = state.users[userIndex]
        user.nom = updatedUser.nom
        user.prenom = updatedUser.prenom
        user.email = updatedUser.email
        user.is_staff = updatedUser.is_staff
        user.is_main_admin = updatedUser.is_main_admin
    },

    activateUser: (state, user_id) => {
        let userIndex = state.users.findIndex((u) => u.id == user_id)
        var user = state.users[userIndex]
        user.is_active = true
    },
    deactivateUser: (state, user_id) => {
        let userIndex = state.users.findIndex((u) => u.id == user_id)
        var user = state.users[userIndex]
        user.is_active = false
    },

    deleteUser: (state, user) => {
        var userIndex = state.users.findIndex(u => u.id == user.id)
        state.users.splice(userIndex, 1)
    },
    updateUserApprobateur: (state, approbation) => {
        var userIndex = state.users.findIndex(u => u.id == approbation.utilisateur)
        var user = state.users[userIndex]
        user.approbateur_fdt = approbation.approbateur
    },
    deleteUserApprobateur: (state, approbation) => {
        var userIndex = state.users.findIndex(u => u.id == approbation.utilisateur)
        var user = state.users[userIndex]
        user.approbateur_fdt = ""
    },

    logout: (state) => {
        state.user = null
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}