<template>
  <AdminTimesheetComponent></AdminTimesheetComponent>
</template>

<script>
import AdminTimesheetComponent from "@/components/AdminTimesheetComponent.vue";
export default {
  name: "AdminTimesheet.vue",
  components: { AdminTimesheetComponent },
};
</script>