<template>
  <div class="d-flex flex-column align-center">
    <div class="col-md-12">
      <v-row v-if="isMobile">
        <v-col>
          <v-btn @click="selectWeekOne" :class="isWeekOneSelected ? 'primary' : ''">Semaine 1</v-btn>
        </v-col>
        <v-col>
          <v-btn @click="selectWeekTwo" :class="isWeekTwoSelected ? 'primary' : ''">Semaine 2</v-btn>
        </v-col>
      </v-row>
      <br />

      <v-simple-table style="width:100%">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Projets</th>
              <th :class="getCellClass(header.dateObject)" v-for="(header, index) in headers" :key="index">
                {{ header.dateString }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(projet, indexProjet) in entriesProjects" v-bind:key="indexProjet">
              <td class="projet">
                {{ projet }}
              </td>

              <td :class="getCellClass(header.dateObject) + ' entrees'" v-for="(header, index) in headers" :key="index">
                <div v-if="header.entrees != undefined">
                  <v-card v-for="entree in header.entrees[projet]" :key="entree.id">
                    <v-card-title> {{ entree.nom_tache }}</v-card-title>

                    <v-card-text>
                      <div>
                        {{ formatHeureText(entree.nombre_heures) }}
                      </div>
                    </v-card-text>

                    <v-card-actions>
                      <EntreeEditComponent :taches="taches" v-bind:projets="projetsFilteredForRandD"
                        v-bind:entree="entree" v-on:onSave="updateEntreeFdt">
                      </EntreeEditComponent>
                      <v-btn color="red" icon x-small @click="deleteAction(entree)"><v-icon>mdi-delete</v-icon></v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
              </td>
            </tr>

            <tr>
              <td style="font-weight:bold">Total heures</td>
              <td style="font-weight:bold" :class="getCellClass(header.dateObject)" v-for="(header, index) in headers"
                :key="index">
                {{ formatHeureText(header.total_heures) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <br />
    </div>
    <br />

    <ConfirmationPromptComponent ref="confirmationPrompt" titre="Confirmer suppression" v-bind:text="promptText"
      v-bind:callback="promptCallback"></ConfirmationPromptComponent>
  </div>
</template>

<script>
import EntreeEditComponent from "./EntreeEditComponent.vue";
import ConfirmationPromptComponent from "@/components/Dialog/ConfirmationPromptComponent.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "TimesheetEntreesCalendarComponent",
  props: ["periode", "feuille", "entrees","projets","taches"],
  components: { EntreeEditComponent, ConfirmationPromptComponent },
  data: () => ({
    date_debut: null,
    daysInTable: 14,
    isWeekOneSelected: true,
    isWeekTwoSelected: false,
    promptText: "",
    promptCallback: null,
  }),
  computed: {
    utilisateur() {
      return this.getUser();
    },

    isUserFeuille() {
      return this.feuille.utilisateur == this.getUser().id;
    },

    isFeuilleCourante() {
      return this.feuille.feuille_courante
    },

    canUseForm() {
      if (this.isBrouillonOrEnAttente()) {
        if (this.isAdmin()) {
          return true
        }
        else
          return this.isUserFeuille
      }
      else
        return false
    }
    ,

    entriesPerDayAndPerProject() {
      let result = this.entrees.reduce((accumulator, entree) => {
        let date = entree.date
        let projet = entree.nom_projet

        if (Object.keys(accumulator).includes(date)) {
          var dateEntry = accumulator[date]

          if (Object.keys(dateEntry).includes(projet)) {
            dateEntry[projet].push(entree)
          }
          else {
            dateEntry[projet] = [entree]
          }
        }
        else {
          accumulator[date] = {}
          accumulator[date][projet] = [entree]
        }
        return accumulator
      }, {})

      return result
    },

    entriesProjects() {
      return this.entrees.reduce((accumulator, entree) => {
        let projet = entree.nom_projet
        if (!accumulator.includes(projet)) {
          accumulator.push(projet)
        }

        return accumulator
      }, [])
    },

    headers() {
      var dateTime = this.$datetime;
      // var dateObject = dateTime.fromISO(this.date_debut). .startOf("week");
      var dateObject = dateTime.fromISO(this.periode.date_debut);
      if (dateObject.weekday != 7) {
        dateObject = dateObject.minus({ weeks: 1 }).set({ weekday: 7 });
      }

      if (this.isWeekTwoSelected) {
        dateObject = dateObject.plus({ weeks: 1 });
      }

      var dateString = dateObject.toFormat("DDDD");
      var dateKey = dateObject.toFormat("yyyy-MM-dd");

      let result = [];
      var i = 1;

      do {

        dateString = this.shortenDateString(dateString)


        let entreesPerProject = this.entriesPerDayAndPerProject[dateKey]
        let totalHeuresDay = 0
        if (entreesPerProject != undefined) {
          Object.values(entreesPerProject).forEach((entrees) => {
            entrees.map((e) => {
              totalHeuresDay += e.nombre_heures
            })
          })
        }

        var header = { dateString, dateObject, dateKey, total_heures: totalHeuresDay, entrees: entreesPerProject };
        result.push(header);
        dateObject = dateObject.plus({ days: 1 });
        dateString = dateObject.toFormat("DDDD");
        dateKey = dateObject.toFormat("yyyy-MM-dd");
        i++;
      } while (i <= this.daysInTable);

      return result
    },

    projetsFilteredForRandD() {
      var isUserAffectedtoRetD = this.isAffectedToRetD();
      return this.projets.filter((p) => {
        if (p.nom_projet == "R&D") {
          if (isUserAffectedtoRetD) return true;
          else return false;
        } else return p.rEtD == false;
      });
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    ...mapActions(["fetchProjets", "fetchTaches", "updateEntree", "deleteEntree"]),
    ...mapGetters(["getUser", "isAffectedToRetD", "getProjets", "isAdmin",
      "isEnAttente",
      "isBrouillon",
      "isApprouve",
      "isSoumis",
      "getStatutClass",]),

    async updateEntreeFdt(entree) {
      this.updateEntree(entree)
    },

    deleteAction(entree) {
      this.promptText = "Supprimer l'entrée?";
      this.promptCallback = () => {
        this.deleteEntree(entree);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },



    getEntreesForDayAndProjet(date, projet) {
      try {
        let result = this.entriesPerDayAndPerProject[date][projet]
        return result
      }
      catch (e) {
        return undefined
      }
    },

    formatHeureText(nb_heures) {
      if (nb_heures == 0)
        return nb_heures
      if (nb_heures < 2)
        return nb_heures + " heure"

      else
        return nb_heures + " heures"

    },

    shortenDateString(dateString) {
      const daysCharNb = 3
      var monthsCharNb = 3

      let parts = dateString.split(" ")
      let day = parts[0]
      let month = parts[2]

      monthsCharNb = month == "juillet" ? 4 : 3;

      day = day.substring(0, daysCharNb)
      month = month.substring(0, monthsCharNb)

      let date = day + " " + parts[1] + " " + month + " " + parts[3]

      return date
    },

    selectWeekOne() {
      this.isWeekOneSelected = true
      this.isWeekTwoSelected = false
    },

    selectWeekTwo() {
      this.isWeekOneSelected = false
      this.isWeekTwoSelected = true
    },

    quitter() {
      this.$router.push({ path: "/feuille" });
    },

    getCellClass(dateObject) {
      if (dateObject.weekday == 7 || dateObject.weekday == 6) return "bggrey";
      else return "";
    },

    isBrouillonOrEnAttente() {
      let statut = this.feuille.statut;
      return this.isEnAttente()(statut) | this.isBrouillon()(statut);
    },

    isSoumisOrEnAttente() {
      let statut = this.feuille.statut;
      return this.isEnAttente()(statut) | this.isSoumis()(statut);
    },
  },
  created()
  {
    this.daysInTable=this.isMobile?7:14
  }
};
</script>

<style scoped>
.bggrey {
  background-color: lightgray;
}

.v-card {
  margin-top: 5px;
  margin-bottom: 5px;
}

.v-card__title {
  justify-content: center;
  font-size: 0.7rem;
  word-break: normal;
  padding: 5px;
  line-height: normal;
}

.v-card__text {
  font-size: 0.7rem;
  padding: 0px;
}

.v-card__actions {
  justify-content: center;
}

.selected_projet.nom_projet {
  font-weight: bold;
  text-decoration: underline;
}
</style>