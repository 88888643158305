var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-switch',{attrs:{"label":"Afficher les projets R&D"},model:{value:(_vm.showRetDProjets),callback:function ($$v) {_vm.showRetDProjets=$$v},expression:"showRetDProjets"}})],1),_c('v-data-table',{attrs:{"items":_vm.projetsItems,"headers":_vm.headers,"search":_vm.search,"custom-filter":_vm.searchFilter,"single-expand":false,"expanded":_vm.expanded,"show-expand":"","locale":"fr","disable-pagination":"","no-data-text":"Aucune donnée disponible","no-results-text":"Aucun résultat correspondant","footer-props":{
      'items-per-page-text': 'Lignes par page:',
      'page-text': '{0}-{1} sur {2}',
      'items-per-page-all-text': 'Toutes',
    }},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"label":"Recherche"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"expanded-item",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"expandedRow"},[_c('h3',[_vm._v("Tâches")]),_c('div',{staticClass:"tachesChips"},_vm._l((item.taches),function(tache){return _c('v-chip',{key:tache.id},[_vm._v(" "+_vm._s(tache.nom_tache)+" "),(!item.rEtD)?_c('div',{staticStyle:{"float":"right"}},[_c('TacheFormComponent',{attrs:{"titre":"Modifier tâche","tache_id":tache.id,"projet_id":item.id,"edit":true}}),_c('v-btn',{attrs:{"small":"","color":"red","icon":""},on:{"click":function($event){return _vm.deleteTacheBtnClick(tache)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()])}),1)]),(!item.rEtD)?_c('TacheFormComponent',{attrs:{"titre":"Ajouter tâche","projet_id":item.id,"edit":false}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('td',[_c('ProjetFormComponent',{attrs:{"titre":"Modifier projet","projet_id":item.id,"edit":true}}),_c('v-btn',{attrs:{"small":"","color":"red","icon":""},on:{"click":function($event){return _vm.deleteProjetBtnClick(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}])}),_c('div',[_c('ProjetFormComponent',{attrs:{"titre":"Ajouter projet","edit":false}})],1),_c('ConfirmationPromptComponent',{ref:"confirmationPrompt",attrs:{"titre":"Confirmer suppression","text":_vm.promptText,"callback":_vm.promptCallback}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }