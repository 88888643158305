<template>
  <div id="nav" class="text-center">
    <div class="userProfile">
      <p>{{ user.prenom }} {{ user.nom }}</p>
      <p>{{ user.email }}</p>
      <v-btn @click="deconnexion" >Deconnexion</v-btn>
    </div>
    <br>
    <hr>
    <br>
    <div class="content">
        <v-btn @click="feuille">Feuilles de temps</v-btn>
        <br>
        <br>
        <v-btn @click="tableauDeBord">Tableau de bord</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SideNavComponent",
  methods: { ...mapGetters(["getUser"]),
  deconnexion()
    {
      this.$router.push({name:'Logout'})
    },
    tableauDeBord()
    {
      this.$router.push({name:'Dashboard'})
    } ,
    feuille()
    {
      this.$router.push({name:'Feuilles de temps'})
    }},
  computed: {
    user() {
      return this.getUser();
    },
  },
};
</script>

<style scoped>
#nav
{
  padding: 15px;
}
#nav div{
  margin: 0 auto;
}

</style>