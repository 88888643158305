<template>
  <div>
    <NavMenuComponent :menuData="menuData"></NavMenuComponent>
    <div><h1>Priorites Projets R&D</h1></div>
    <div v-if="dataFetched">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Projet R&D</th>
              <th class="text-left">Priorité</th>
              <th class="text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="priorite in priorites" :key="priorite.id">
              <td>{{priorite.nom_projet}}</td>
              <td>{{priorite.priorite}}</td>
              <td><div><v-btn icon color="red" @click="deleteAction(priorite)"><v-icon>mdi-delete</v-icon></v-btn></div></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div>
        <PrioriteProjetsRetDForm :edit="false"></PrioriteProjetsRetDForm>
      </div>
      <ConfirmationPromptComponent
      ref="confirmationPrompt"
      titre="Confirmer suppression"
      v-bind:text="promptText"
      v-bind:callback="promptCallback"
    ></ConfirmationPromptComponent>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import PrioriteProjetsRetDForm from "./PrioriteProjetsRetDForm.vue"
import ConfirmationPromptComponent from "./Dialog/ConfirmationPromptComponent.vue"
import NavMenuComponent from "./NavMenuComponent.vue"
export default {
  name: "AdminPrioritesProjetsRetDComponent",
  components:{PrioriteProjetsRetDForm,ConfirmationPromptComponent,NavMenuComponent},
  data: () => ({
    priorites: null,
    dataFetched: false,
    promptText:null,
    promptCallback:null,
    menuData:[{
      title:"Affectations projets R&D",
      link:"/admin/projetsretd/affectations"
    },{
      title:"Priorités projets R&D",
      link:"/admin/projetsretd/priorites"
    }]
  }),
  methods: {
    ...mapActions(["fetchPriorites","deletePriorite"]),
     deleteAction(priorite) {
      this.promptText =
        "Supprimer le projet " + priorite.nom_projet + " de la liste des priorités ?";
      this.promptCallback = () => {
        this.deletePriorite(priorite);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
  },
  created() {
    this.fetchPriorites().then((p) => {
      this.priorites = p;
      this.dataFetched = true;
    });
  },
};
</script>