<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker locale="fr" :min="minDate" :max="maxDate" v-model="date" v-on:change="saveDate" no-title scrollable reactive>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false"> Annuler </v-btn>
      <v-btn text color="primary" @click="saveDate"> OK </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DatePickerComponent",
  data: () => ({
    date: "",
    menu: false,
  }),
  props: ["label", "previousDate","minDate","maxDate"],
  methods: {
    saveDate() {
      this.$refs.menu.save(this.date);
      this.emittEvent();
    },
    emittEvent() {
      this.$emit("dateSaved", this.date);
    },
  },
  watch: {
    previousDate(value) {
        this.date = value;
    },
  },
  created()
  {
    this.date=this.previousDate
  }
};
</script>