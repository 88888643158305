<template>
  <div class="col-md-4" style="margin: 0 auto">
    <h1>Connexion double facteur</h1>
    <br />
    <p>Un code vous a été envoyé a votre numéro de télephone pour vérification</p>
    <v-alert type="error" v-if="error">Erreur, code invalide ou expiré</v-alert>
    <v-form @submit="connexion">
      <div>
        <div v-if="$v.code.$dirty">
          <v-alert class="error" v-if="!$v.code.required">
            Ce champ est requis
          </v-alert>
        </div>
        <v-text-field
          v-model="$v.code.$model"
          type="number"
          label="Code de vérification"
        >
        </v-text-field>
      </div>
      <v-btn type="submit">Connexion</v-btn>
    </v-form>
    <br />
    <div>
      <v-btn @click="resendCode">Renvoyer le code</v-btn>
    </div>
    <v-snackbar v-model="snackbar">
      Code renvoyé
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "LoginComponent",
  data: () => ({
    code: "",
    snackbar: false,
    error: false,
  }),
  methods: {
    ...mapActions([
      "fetchUserTwoFactorStepTwo",
      "resendCodeTwoFactorAuthentication",
    ]),
    connexion: function (e) {
      e.preventDefault();
      this.error = false;
      var code = { code: this.code.toString() };
      this.fetchUserTwoFactorStepTwo(code).then((user) => {
        if (user == null) {
          this.error = true;
        } else {
          this.$router.push({ path: "/" });
        }
      });
    },
    resendCode() {
      this.error = false;
      this.resendCodeTwoFactorAuthentication().then((response) => {
        if (response) this.snackbar = true;
        else this.error = true;
      });
    },
  },
  validations: {
    code: {
      required,
    },
  },
};
</script>