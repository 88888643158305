<template>
    <div class="col-md-8" style="margin:0 auto">
        <h1>Projets</h1>
        <hr>
        <ProjetsTableComponent></ProjetsTableComponent>
    </div>
</template>

<script>
import ProjetsTableComponent from "./ProjetsTableComponent.vue"
export default {
    name:"ProjetManagementComponent",
    components:{ProjetsTableComponent}
}
</script>