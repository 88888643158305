<template>
    <ProjetManagementComponent></ProjetManagementComponent>
</template>

<script>
import ProjetManagementComponent from "@/components/ProjetManagementComponent"
export default {
    name:"Projets",
    components:{ProjetManagementComponent}
}
</script>