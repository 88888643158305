<template>
  <div class="col-md-4" style="margin: 0 auto">
    <h1>Connexion</h1>
    <v-alert type="error" v-if="error"
      >Erreur dans l'adresse email ou le mot de passe</v-alert
    >
    <v-alert type="error" v-if="admin_redirect"
      >Vous devez être connecté en tant qu'administrateur pour accéder a cette
      page</v-alert
    >
    <v-form @submit="connexion">
      <v-text-field v-model="email" label="Adresse email"> </v-text-field>
      <v-text-field
        v-model="password"
        label="Mot de passe"
        :type="password_visible?'text':'password'"
       :append-icon="password_visible ? 'mdi-eye-off':'mdi-eye'"
       @click:append="password_visible=!password_visible"
      ></v-text-field>
      <br />
      <v-btn type="submit">Connexion</v-btn>
    </v-form>
    <br/>
    <div>
      <router-link  to="/connexion/reinitialiser">Mot de passe oublié</router-link>
    </div>
    <br/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "LoginComponent",
  methods: {
    ...mapActions(["fetchUser","fetchUserTwoFactorStepOne"]),
    connexion: function (e) {
      e.preventDefault();
      var credentials = { username: this.email, password: this.password };
      if(this.twoFactor)
      {
        this.doubleFactorAuthentication(credentials)
      }
      else
      {
        this.singleFactorAuthentication(credentials)
      }
    },
    singleFactorAuthentication(credentials) {
      this.fetchUser(credentials).then((response) => {
        if (response.success) {
          this.$router.push({ path: "/" });
        } else {
          try {
            if (response.activate) {
              this.$router.push({ path: "/verification" });
            } else {
              this.error = true;
            }
          } catch (e) {
            this.error = true;
          }
        }
      });
    },
    doubleFactorAuthentication(credentials) {
      this.fetchUserTwoFactorStepOne(credentials).then((response) => {
        if (response.success) {
          this.$router.push({ path: "/connexion/doublefacteur" });
        } else {
          try {
            if (response.activate) {
              this.$router.push({ path: "/verification" });
            } else {
              this.error = true;
            }
          } catch (e) {
            this.error = true;
          }
        }
      });
    },
  },
  data: () => ({
    email: "",
    password: "",
    password_visible: false,
    twoFactor:false,
    error: false
  }),
  computed: {
    admin_redirect() {
      return this.$route.query.admin_redirect
    }
  }
}
</script>