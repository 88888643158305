<template>
  <div class="col-md-8" style="margin: 0 auto">
    <h1>Mes feuilles de temps</h1>
    <br />
    <div v-if="dataFetched">
      <div>
        <h2>Feuille de temps (période courante)</h2>
        <hr />
        <br />
        <div v-if="feuilleCourante != null">
          <TimesheetItemComponent :feuille="feuilleCourante" origin="courante"></TimesheetItemComponent>
        </div>
        <div v-if="feuilleCourante == null">
          <p>Aucune feuille de temps pour la periode courante</p>
          <v-btn @click="createFeuilleCouranteClick">Créer</v-btn>
        </div>
      </div>
      <br />
      <br />
      <div>
        <h2>Feuilles de temps précédentes</h2>
        <br />
        <div>
          <v-select v-model="periode" :items="periodesPrecedentes" item-text="nom_periode" return-object
            label="Période de paie" @change="periodeChange"></v-select>
        </div>
        <br />
        <div v-if="feuillesPeriode != null">
          <TimesheetItemComponent :feuille="feuillesPeriode" origin="precedente"></TimesheetItemComponent>
        </div>
        <div v-if="feuillesPeriode == null">
          <p>Aucune feuille de temps pour cette période</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TimesheetItemComponent from "./TimesheetItemComponent.vue";
export default {
  name: "TimesheetComponent",
  components: {
    TimesheetItemComponent,
  },
  data: () => ({
    dataFetchedTable: [false, false],
    feuilleCourante: null,
    feuillesPeriode: null,
    periodes: null,
    periode: null,
  }),
  methods: {
    ...mapActions([
      "fetchFeuilleCourante",
      "fetchFeuillesDeTemps",
      "fetchUserFeuilleDeTempsPeriode",
      "createFeuillePeriodeCourante",
      "fetchPeriodes",
      "setFeuilleCourante"
    ]),
    ...mapGetters(["getUser", "getFeuilleCourante"]),
    async createFeuilleCouranteClick() {
      this.feuilleCourante = await this.createFeuillePeriodeCourante(this.getUser().id);
    },
    periodeChange() {
      var data = {
        id_user: this.getUser().id,
        id_periode: this.periode.id,
      };
      this.fetchUserFeuilleDeTempsPeriode(data).then((f) => {
        this.feuillesPeriode = f;
      });
    },
  },
  computed: {
    // feuilleCourante() {
    //   return this.getFeuilleCourante();
    // },
    periodeCourante() {
      return this.periodes.find((p) => p.periode_courante);
    },
    periodesPrecedentes() {
      return this.periodes.filter((p) => !p.periode_courante);
    },
    dataFetched() {
      var result = true;
      for (var i = 0; i < this.dataFetchedTable.length; i++) {
        if (!this.dataFetchedTable[i]) result = false;
      }
      return result;
    },
  },
  created() {
    this.fetchPeriodes().then((p) => {
      this.periodes = p;
      try {
        var data = {
          id_user: this.getUser().id,
          id_periode: this.periodeCourante.id,
        };
        this.fetchUserFeuilleDeTempsPeriode(data).then((feuilleCourante) => {
          this.feuilleCourante = feuilleCourante
          this.setFeuilleCourante(feuilleCourante)
          this.$set(this.dataFetchedTable, 0, true);

        });
        this.periode = p[1];
      } catch (e) {
        console.log(e);
        this.$set(this.dataFetchedTable, 0, true);
      }
      try {
        var data2 = {
          id_user: this.getUser().id,
          id_periode: this.periode.id,
        };
        this.fetchUserFeuilleDeTempsPeriode(data2).then((f) => {
          this.feuillesPeriode = f;
          this.$set(this.dataFetchedTable, 1, true);
        });
      } catch (e) {
        console.log(e);
        this.$set(this.dataFetchedTable, 1, true);
      }
    });
  },
};
</script>